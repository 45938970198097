import { stAnalytics } from "@repo/analytics";
import { type CommandKey, codes, type curator, getRequestClient, type q, type stid } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import type { Editor } from "@tiptap/core";
import { useActor } from "@xstate/solid";
import dayjs from "dayjs";
import { type Accessor, createMemo, createResource } from "solid-js";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import type { CollectionsService } from "~/domains/collections/collections.service";
import type { WorkingContext } from "~/domains/identity/types";
import { useThreadMachine } from "~/domains/threads/machines";
import { threadEventFactory } from "~/domains/threads/machines/threadsEventProducers";
import type { Prompt } from "~/domains/threads/types";
import { newSendToServerEvent } from "~/domains/ws/machines";
import { getMessagesWithInserts } from "../machines/threadInserts";
import {
  createFetchCollectionThreadsResource,
  createThreadsStore,
  getThread,
  getThreadCollections,
  getThreadOrFail,
  updateRemoveThread,
  updateThread,
  updateThreadEntitiesBatch,
  updateThreadFromState,
  updateThreadLabel,
  updateThreadsBatch,
} from "../threads.store";
import type { ThreadEntity, ThreadSnapshot } from "../threads.types";

export type ThreadServiceDependencies = {
  logger: Logger;
  getAuthToken: () => string | undefined;
  workingContext: Accessor<WorkingContext>;
  limitingAddAllowedThread: (id: string) => void;
  limitingIsThreadAllowed: (id: string) => boolean;
  limitingIsInteractionAllowed: () => boolean;
  updateCollections: CollectionsService["setCollections"];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  sendWsMessage: (event: any) => void;
  getAssetById: (id: string) => q.ControlplaneSsAsset | undefined;
  updateAsset: (asset: q.ControlplaneSsAsset) => void;
  wsMessageIn: Accessor<number>;
  isIdentityConnecting: () => boolean;
  userId: Accessor<string | undefined>;
};

export type ThreadService = ReturnType<typeof useThreadService>;

export const useThreadService = (deps: ThreadServiceDependencies) => {
  const childLogger = new Named(deps.logger, "threadService");
  const eventFactory = threadEventFactory(childLogger);

  const [store, setStore] = createThreadsStore();
  const storeGetThreadCollections = (threadId: string) => getThreadCollections(store, threadId);
  const storeUpdateThreadEntitiesBatch = (threads: ThreadEntity[]) => updateThreadEntitiesBatch(setStore, threads);
  const storeUpdateThread = (thread: ThreadSnapshot) => updateThread(setStore, thread);
  const storeUpdateThreadsBatch = (threads: ThreadSnapshot[]) => updateThreadsBatch(setStore, threads);
  const storeUpdateThreadLabel = (threadId: string, label: string) => updateThreadLabel(setStore, threadId, label);
  const storeUpdateRemoveThread = (threadId: string) => updateRemoveThread(setStore, threadId);
  const storeGetThread = (threadId: string) => getThread(store, threadId);
  const storeGetThreadOrFail = (threadId: string) => getThreadOrFail(store, threadId);

  const threadsListResource = useThreadsList({
    ...deps,
    updateThreadEntities: storeUpdateThreadEntitiesBatch,
    updateThreadLabel: storeUpdateThreadLabel,
  });

  const createThread = async (params: {
    label: string;
    collectionId: string;
    organizationId: string;
    tenantId: string;
  }) => {
    childLogger.info("creating thread", params);

    const [_threadsList, { refetch }] = threadsListResource;

    const client = getRequestClient(deps.getAuthToken);

    const results = await client.controlplane.CreateThread({
      label: params.label,
      collectionId: params.collectionId,
      organizationContext: {
        organizationId: params.organizationId,
        tenantId: params.tenantId,
      },
      assetIds: [],
      collectionIds: [],
    });

    if (results.code !== codes.OK) {
      throw Error(results.message);
    }

    updateThreadFromState(setStore, results.data);

    stAnalytics.track(
      "thread_created",
      getThreadEventProperties({
        workingContext: deps.workingContext(),
        threadId: results.data.threadId,
        threadMessages: messages(),
      }),
    );

    deps.limitingAddAllowedThread(results.data.threadId);
    refetch();

    return results;
  };

  const loadThread = async (threadId: stid.ThreadStringID, token?: string) => {
    childLogger.info("loading thread", {
      threadId,
      token: deps.getAuthToken(),
    });
    const client = getRequestClient(deps.getAuthToken);
    const results = await client.controlplane.GetThreadByID(threadId, {
      PublicAccessToken: token ?? "",
    });
    childLogger.info("API call to get thread complete", { results });

    if (results.code !== codes.OK) {
      throw Error(results.message);
    }

    updateThreadFromState(setStore, results.data);

    stAnalytics.track(
      "thread_opened",
      getThreadEventProperties({
        workingContext: deps.workingContext(),
        threadId: results.data.threadId,
        threadMessages: messages(),
      }),
    );
    return results;
  };

  /**
   * onSendServerWSMessage is a callback that is called when the state machine wants to send a message to the server.
   * @param commandKey
   * @param recipients
   * @param data
   */
  const onSendServerWSMessage = (commandKey: CommandKey, recipients: string[], data: string): void => {
    deps.sendWsMessage(newSendToServerEvent(commandKey, recipients, data));
  };

  const [snapshot, send, actor] = useActor(
    useThreadMachine({
      logger: deps.logger,
      createThread,
      loadThread,
      onSendServerWSMessage,
      getAssetById: deps.getAssetById,
      updateAsset: deps.updateAsset,
      workingContext: deps.workingContext,
      wsMessageIn: deps.wsMessageIn,
    }),
    // { inspect: isDev ? createBrowserInspector().inspect : undefined },
  );

  const messages = createMemo(() => getMessagesWithInserts({ ...deps, messages: snapshot.context.messages }));

  const updateEditorRef = (editorRef: Editor | null) => {
    send(eventFactory.newUpdateEditorRef(editorRef));
  };

  const logger = new Named(deps.logger, "threadService");
  actor.start();

  if (_LOG) logger.info("service started");

  const getSignedURLForMessageDiagnostics = async (
    organizationID: stid.OrganizationStringID,
    threadID: stid.ThreadStringID,
    messageID: stid.MessageStringID,
  ): Promise<string> => {
    const client = getRequestClient(deps.getAuthToken);
    const results = await client.controlplane.GenerateSignedURLForMessageDiagnostics(
      organizationID,
      threadID,
      messageID,
    );
    if (results.code === codes.OK) {
      await navigator.clipboard.writeText(results.data);
      alert("Copied to Clipboard");
    }
    return "";
  };

  const sendThreadKnowledge = async (params: {
    knowledge: curator.Knowledge;
    collectionId: string;
    organizationId: string;
    tenantId: string;
  }) =>
    send(
      threadEventFactory(logger).newSendKnowledgeChangeEvent(
        params.knowledge,
        params.collectionId,
        params.organizationId,
        params.tenantId,
      ),
    );

  const sendThreadPrompt = async (params: {
    prompt: Prompt;
    collectionId: string;
    organizationId: string;
    tenantId: string;
  }) =>
    send(
      threadEventFactory(logger).newSendPrompt(
        params.prompt,
        params.collectionId,
        params.organizationId,
        params.tenantId,
      ),
    );

  return {
    messages,
    snapshot,
    send,
    actor,
    eventFactory,
    getSignedURLForMessageDiagnostics,
    sendThreadKnowledge,
    sendThreadPrompt,
    updateEditorRef,
    threadsListResource,
    loadThread,
    createThread,

    // New store methods
    updateThread: storeUpdateThread,
    updateThreadLabel: storeUpdateThreadLabel,
    updateThreads: storeUpdateThreadsBatch,
    updateRemoveThread: storeUpdateRemoveThread,
    getThread: storeGetThread,
    getThreadOrFail: storeGetThreadOrFail,
    getThreadCollections: storeGetThreadCollections,

    // Resource creators
    resourceCollectionThreads: (collectionId: Accessor<string | undefined>) =>
      createFetchCollectionThreadsResource(
        setStore,
        deps.updateCollections,
        deps.getAuthToken,
        () => !deps.isIdentityConnecting(),
        collectionId,
      ),
  };
};

const useThreadsList = ({
  getAuthToken,
  updateCollections,
  updateThreadEntities,
  isIdentityConnecting,
  userId,
  limitingIsThreadAllowed,
  updateThreadLabel: storeUpdateThreadLabel,
}: ThreadServiceDependencies & {
  updateThreadEntities: (threads: ThreadEntity[]) => void;
  updateThreadLabel: (threadId: string, label: string) => void;
}) => {
  const isConnecting = () => isIdentityConnecting();
  const client = getRequestClient(getAuthToken);
  const [threads, { mutate, refetch }] = createResource(
    () => !isConnecting() && userId(),
    async () => {
      const res = await client.controlplane.GetThreadsRecentForUser({
        Offset: 0,
        Limit: 5,
        Sort: [""],
      });

      updateThreadEntities(res.data.result.entities);
      // Updating Collections
      updateCollections(Object.values(res.data.result.collections).map((c) => c.data));

      // Filter out disallowed threads
      res.data.result.entities = res.data.result.entities?.filter((t) => limitingIsThreadAllowed(t.data.threadId));

      return res;
    },
  );
  const updateThreadLabel = (threadId: string, label: string) => {
    storeUpdateThreadLabel(threadId, label);
    mutate((t) => {
      if (!t?.data) return t;
      const index = t.data.result.entities.findIndex((item) => item.data.threadId === threadId);
      if (index === -1) return t;
      const newData = t.data.result.entities.map((item) => {
        if (item.data.threadId === threadId) return { ...item, data: { ...item.data, label } };
        return item;
      });

      return {
        ...t,
        data: {
          ...t.data,
          result: {
            ...t.data.result,
            entities: newData,
          },
        },
      };
    });
  };

  const removeThreadFromList = (threadId: string) => {
    mutate((t) => {
      if (!t?.data) return t;

      return {
        ...t,
        data: {
          ...t.data,
          result: {
            ...t.data.result,
            entities: t.data.result.entities?.filter((thread) => thread.data.threadId !== threadId),
          },
        },
      };
    });
  };
  return [threads, { updateThreadLabel, refetch, removeThreadFromList }] as const;
};

export const threadsByRecent = (threads: ThreadSnapshot[] | undefined) => {
  const today: ThreadSnapshot[] = [];
  const yesterday: ThreadSnapshot[] = [];
  const week: ThreadSnapshot[] = [];
  const month: ThreadSnapshot[] = [];

  threads?.forEach((item) => {
    const date = dayjs(item.modifiedAt);
    const diffDays = dayjs().diff(date, "day");
    if (diffDays <= 1) today.push(item);
    else if (diffDays <= 2) yesterday.push(item);
    else if (diffDays <= 7) week.push(item);
    else if (diffDays <= 30) month.push(item);
  });

  return [
    ...(today.length
      ? [
          {
            label: "Chats",
            children: today,
          },
        ]
      : []),
    ...(yesterday.length
      ? [
          {
            label: "Yesterday",
            children: yesterday,
          },
        ]
      : []),
    ...(week.length
      ? [
          {
            label: "Last week",
            children: week,
          },
        ]
      : []),
    ...(month.length
      ? [
          {
            label: "Last month",
            children: month,
          },
        ]
      : []),
  ];
};

export type ThreadsGroupedByTime = ReturnType<typeof threadsByRecent>;
