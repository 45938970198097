// Code generated by the Encore v1.46.4 client generator. DO NOT EDIT.

// Disable eslint, jshint, and jslint for this file.
/* eslint-disable */
/* jshint ignore:start */
/*jslint-disable*/

/**
 * BaseURL is the base URL for calling the Encore application's API.
 */
export type BaseURL = string

export const Local: BaseURL = "http://localhost:4000"

/**
 * Environment returns a BaseURL for calling the cloud environment with the given name.
 */
export function Environment(name: string): BaseURL {
    return `https://${name}-storytell-ai-platfor-cds2.encr.app`
}

/**
 * PreviewEnv returns a BaseURL for calling the preview environment with the given PR number.
 */
export function PreviewEnv(pr: number | string): BaseURL {
    return Environment(`pr${pr}`)
}

/**
 * Client is an API client for the storytell-ai-platfor-cds2 Encore application.
 */
export default class Client {
    public readonly controlplane: controlplane.ServiceClient


    /**
     * @deprecated This constructor is deprecated, and you should move to using BaseURL with an Options object
     */
    constructor(target: string, token?: string)

    /**
     * Creates a Client for calling the public and authenticated APIs of your Encore application.
     *
     * @param target  The target which the client should be configured to use. See Local and Environment for options.
     * @param options Options for the client
     */
    constructor(target: BaseURL, options?: ClientOptions)
    constructor(target: string | BaseURL = "prod", options?: string | ClientOptions) {

        // Convert the old constructor parameters to a BaseURL object and a ClientOptions object
        if (!target.startsWith("http://") && !target.startsWith("https://")) {
            target = Environment(target)
        }

        if (typeof options === "string") {
            options = { auth: options }
        }

        const base = new BaseClient(target, options ?? {})
        this.controlplane = new controlplane.ServiceClient(base)
    }
}

/**
 * ClientOptions allows you to override any default behaviour within the generated Encore client.
 */
export interface ClientOptions {
    /**
     * By default the client will use the inbuilt fetch function for making the API requests.
     * however you can override it with your own implementation here if you want to run custom
     * code on each API request made or response received.
     */
    fetcher?: Fetcher

    /** Default RequestInit to be used for the client */
    requestInit?: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }

    /**
     * Allows you to set the auth token to be used for each request
     * either by passing in a static token string or by passing in a function
     * which returns the auth token.
     *
     * These tokens will be sent as bearer tokens in the Authorization header.
     */
    auth?: string | AuthDataGenerator
}

export namespace controlplane {
    export type CommandKey = string

    export interface CrawlURLParams {
        url: string
        collectionID: stid.CollectionStringID
        organizationID: stid.OrganizationStringID
        tenantID: stid.OrganizationStringID
        /**
         * ProcessInBackground is a flag that indicates whether the asset should be
         * processed in the background. If false, the trigger to process the asset must
         * occur through the threads domain as knowledge is added. Otherwise, the
         * system will begin processing async in the background.
         */
        processInBackground: boolean

        /**
         * ProcessInThread is a flag that indicates whether the asset should be
         * processed through a websocket message.
         */
        processInThread: boolean
    }

    export interface DeleteCollectionParams {
        collectionIds: string[]
        permanent: boolean
    }

    export interface DeviceID {
        id: string
    }

    export interface GetRecentAssetsForUserParams {
        Page: number
        Sort: string
    }

    export interface GetThreadByIDParams {
        PublicAccessToken: string
    }

    export interface ListRecentAssetsParams {
        limit: number
        offset: number
        /**
         * FilterByLifecycleStates, optional, constrains the search to assets that are
         * in the specified lifecycle states.
         */
        lifecycleStates: string

        /**
         * FilterByContentType, optional, constrains the search to assets that are
         * of a specific content type.
         */
        filterByContentType: string

        /**
         * FilterByAccessTypes, optional, constrains the search to assets that have
         * been accessed in the specified ways.
         */
        filterByAccessTypes: string
    }

    export interface ServerEnvelope {
        commandKey: CommandKey
        recipients: string[]
        data: string
    }

    export interface ServerStatusResult {
        fileproc: status.Response
        fileprocStatusCheckLatencyMS: number
        /**
         * System Info
         */
        "num_cpu": number

        "num_goroutine": number
        "go_version": string
        /**
         * Memory Stats
         */
        alloc: number

        /**
         * Total bytes allocated (even if freed)
         */
        "total_alloc": number

        /**
         * Total bytes obtained from system
         */
        sys: number

        /**
         * Bytes allocated and still in use
         */
        "heap_alloc": number

        /**
         * Bytes obtained from system
         */
        "heap_sys": number

        /**
         * Bytes in idle spans
         */
        "heap_idle": number

        /**
         * Bytes in non-idle spans
         */
        "heap_inuse": number

        /**
         * Bytes used by stack allocator
         */
        "stack_inuse": number

        /**
         * GC Stats
         */
        "num_gc": number

        /**
         * Time of last garbage collection
         */
        "last_gc": string

        /**
         * Total GC pause time
         */
        "pause_total_ns": number

        /**
         * Uptime
         */
        "start_time": string

        uptime: string
    }

    /**
     * TombstoneAssetParams is a struct that represents the parameters for
     * tombstoning assets. Up to 100 assets can be tombstoned per request.
     */
    export interface TombstoneAssetParams {
        assetIds: string[]
    }

    export interface TombstoneThreadParams {
        threadIds: string[]
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Link Assets to Collection
         * Associates multiple assets with a collection, allowing cross-organization
         * relationships with proper access permissions.
         */
        public async AssociateAssetsToCollection(collectionID: string, params: curator.AssociateAssetsToCollectionParams): Promise<operation.Response<curator.AssetToCollectionAssocResults>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/collections/${encodeURIComponent(collectionID)}/assets`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.AssetToCollectionAssocResults>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Link Threads to Collection
         * Associates multiple threads with a collection, allowing cross-organization
         * relationships with proper access permissions.
         */
        public async AssociateThreadsToCollection(collectionID: string, params: curator.AssociateThreadsToCollectionParams): Promise<operation.Response<curator.ThreadToCollectionAssocResults>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/collections/${encodeURIComponent(collectionID)}/threads`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ThreadToCollectionAssocResults>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Auth Sync
         * Creates a new user record or synchronizing an existing account with the
         * authenticated identity from an auth service.
         */
        public async AuthSync(params: users.UserAuthSyncCmdParams): Promise<operation.Response<users.AuthSyncResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/self/auth-sync`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<users.AuthSyncResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Redeem Access Token
         * Grants user permissions by validating and consuming an unclaimed access token,
         * requiring authentication.
         */
        public async ClaimToken(token: string): Promise<operation.Response<curator.ClaimCollectionResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/claim-token/${encodeURIComponent(token)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ClaimCollectionResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * List Collection Members and Pending Invites
         * Returns sorted list of active members and pending invites for a collection. Active
         * members are ordered by display name, pending invites by email. Requires auth and read
         * access. Limited to 100 results per page.
         */
        public async CollectionAccess(collectionID: string, params: pagination.Params): Promise<operation.Response<curator.GetCollectionAccessResult>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                limit:  String(params.Limit),
                offset: String(params.Offset),
                sort:   params.Sort.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/collection-access/${encodeURIComponent(collectionID)}`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.GetCollectionAccessResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Crawl URL
         * Crawls a URL and creates an asset for the content suitable for chat.
         */
        public async CrawlURL(params: CrawlURLParams): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/url/crawl`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Initialize New Asset Upload
         * Creates a new asset record and returns a signed URL for secure file upload to
         * cloud storage.
         */
        public async CreateAsset(params: assets.CreateAssetCmdParams): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/assets`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Add New Collection
         * Creates a new collection under the specified ancestor. Requires user
         * authentication and proper authorization permissions.
         */
        public async CreateCollection(parentCollectionID: string, params: curator.CreateCollectionCmdParams): Promise<operation.Response<curator.CollectionState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/collection/${encodeURIComponent(parentCollectionID)}`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Create Organization Thread
         * Starts a new conversation thread within the authenticated user's organization.
         */
        public async CreateThread(params: curator.CreateThreadCmdParams): Promise<operation.Response<curator.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/threads`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Permanently Delete Collections
         * Irreversibly removes collections and all their contents including assets,
         * threads, and child collections. Multiple collections can be deleted at once,
         * but specified IDs must not include descendant collections.
         */
        public async DeleteCollections(params: DeleteCollectionParams): Promise<operation.Response<operations.MessageResult>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                collectionIds: params.collectionIds.map((v) => v),
                permanent:     String(params.permanent),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("DELETE", `/v1/collections`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<operations.MessageResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Delete Invitation Token
         * Removes an unclaimed invitation token using its internal identifier, not the
         * publicly shared claim code.
         */
        public async DeleteToken(token: string): Promise<operation.Response<boolean>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("DELETE", `/v1/collection-token/${encodeURIComponent(token)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<boolean>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Fallback is called for debug endpoints. Not intended for normal use.
         */
        public async Fallback(method: string, fallback: string[], body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/${fallback.map(encodeURIComponent).join("/")}`, body, options)
        }

        /**
         * Generate Placeholder IDs
         * Creates dummy identifiers to support automated code generation processes.
         */
        public async GenIDs(params: stid.Bag): Promise<session.Session> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/_/gen/ids_validate`, JSON.stringify(params))
            return await resp.json() as session.Session
        }

        /**
         * Placeholder Struct for Server Generation
         * A utility structure that exists solely to support API code generation templates
         * and infrastructure.
         */
        public async GenServerEnvelope(): Promise<ServerEnvelope> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/_/gen/server_envelope`)
            return await resp.json() as ServerEnvelope
        }

        /**
         * Placeholder Struct for Thread Content Types
         * A utility structure that exists solely to support API code generation templates
         * and content type definitions.
         */
        public async GenThreadContentTypes(): Promise<curator.MessageKinds> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/_/gen/threads`)
            return await resp.json() as curator.MessageKinds
        }

        /**
         * Create New Device Identifier
         * Generates a unique identifier for a new device registration.
         */
        public async GenerateDeviceID(): Promise<DeviceID> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/device/generate-id`)
            return await resp.json() as DeviceID
        }

        /**
         * Generate Admin Diagnostic URL
         * Creates a secure, signed URL for administrators to access message diagnostic data.
         */
        public async GenerateSignedURLForMessageDiagnostics(organizationID: string, threadID: string, messageID: string): Promise<operation.Response<string>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/diagnostics/message/${encodeURIComponent(organizationID)}/${encodeURIComponent(threadID)}/${encodeURIComponent(messageID)}/signed-url`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<string>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Get Asset Details
         * Retrieves asset information if the user has read permissions in any collection
         * containing the asset.
         */
        public async GetAssetByID(assetID: string): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/assets/${encodeURIComponent(assetID)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * List Collection Assets
         * Retrieves a collection and its associated assets using the collection identifier.
         */
        public async GetAssetsByCollectionID(collectionID: string, params: pagination.Params): Promise<operation.Response<curator.CollectionAssets>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                limit:  String(params.Limit),
                offset: String(params.Offset),
                sort:   params.Sort.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/collection/${encodeURIComponent(collectionID)}/assets`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionAssets>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Get User's Recent Assets
         * Retrieves a list of assets that the user has recently interacted with or
         * referenced.
         */
        public async GetAssetsRecentForUser(params: GetRecentAssetsForUserParams): Promise<operation.Response<q.ControlplaneSsAsset[]>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                page: String(params.Page),
                sort: params.Sort,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/navigate/recent/assets`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<q.ControlplaneSsAsset[]>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * View Collection Details
         * Fetches a specific collection using its unique identifier. Requires user
         * authentication and proper access permissions.
         */
        public async GetCollectionByID(collectionID: string): Promise<operation.Response<curator.CollectionState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/collections/${encodeURIComponent(collectionID)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * View Collection Hierarchy
         * Retrieves the complete hierarchical structure of a collection. Requires user
         * authentication and proper access permissions.
         */
        public async GetCollectionTree(): Promise<operation.Response<curator.CollectionTrees>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/self/collection-tree`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionTrees>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Health Check
         * Returns the status of the service.
         */
        public async GetStatus(): Promise<operation.Response<ServerStatusResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/status`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<ServerStatusResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Get Thread Details
         * Retrieves a specific thread using its ID. Requires authentication and active focus on
         * the thread's tenant organization.
         */
        public async GetThreadByID(threadID: string, params: GetThreadByIDParams): Promise<operation.Response<curator.ThreadState>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                publicAccessToken: params.PublicAccessToken,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/threads/${encodeURIComponent(threadID)}`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * List Collection Threads
         * Retrieves a collection and all associated discussion threads using the collection
         * identifier.
         */
        public async GetThreadsByCollectionID(collectionID: string, params: pagination.Params): Promise<operation.Response<curator.CollectionThreads>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                limit:  String(params.Limit),
                offset: String(params.Offset),
                sort:   params.Sort.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/collection/${encodeURIComponent(collectionID)}/threads`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionThreads>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * List User's Recent Threads
         * Retrieves the most recent conversation threads associated with the current user.
         */
        public async GetThreadsRecentForUser(params: pagination.Params): Promise<operation.Response<curator.CollectionThreads>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                limit:  String(params.Limit),
                offset: String(params.Offset),
                sort:   params.Sort.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/navigate/recent/threads`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionThreads>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Process Thread Message
         * Handles and processes an incoming message within an existing conversation thread.
         */
        public async HandleThreadMessage(threadID: string, params: curator.MessageCmdParams): Promise<operation.Response<curator.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/messages`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Repair Missing User Data
         * A health check that ensures user snapshots are always current.
         */
        public async HealMissingUserSnapshots(): Promise<operation.Response<users.HealSnapshotResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/ops/heal-missing-user-snapshots`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<users.HealSnapshotResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Merge User Accounts
         * Combines two user accounts, transferring data from one account to another
         * while preserving important user information.
         */
        public async MergeUsers(params: users.MergeUsersParams): Promise<operation.Response<users.MergeUserResults>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/users/merge`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<users.MergeUserResults>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Update Collection Access Rights
         * Modifies user permissions for a shared collection. Updates can add new permissions,
         * maintain existing ones, or remove a user's access entirely by providing no actions.
         * Requires authentication and collection sharing authorization.
         */
        public async ModifyCollectionPermissions(params: curator.ModifyCollectionPermissionsParams): Promise<operation.Response<operations.MessageResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/modify-collection-permissions`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<operations.MessageResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Move Collection and Descendants
         * Relocates a collection and its child collections to a new parent collection,
         * returning list of all modified collections.
         */
        public async MoveCollection(collectionID: string, params: curator.MoveCollectionArguments): Promise<operation.Response<curator.CollectionState[]>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/collection/${encodeURIComponent(collectionID)}/move`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionState[]>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Register Uploaded Asset
         * Triggers asset fingerprinting and indexing after upload completion, making the asset
         * available for chat.
         */
        public async NotifyAssetUploaded(params: assets.OnUploadAssetCmdParams): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/assets/notifyUploaded`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Enhance Prompt
         * Enhances a prompt to help users write better prompts for thier use cases.
         */
        public async PromptEnhancer(params: curator.PromptEnhancementsCmdParams): Promise<operation.Response<curator.PromptEnhancementsResponse>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/prompt/enhance`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.PromptEnhancementsResponse>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * RecentAssets searches for assets by name.
         */
        public async RecentAssets(params: ListRecentAssetsParams): Promise<operation.Response<curator.CollectionAssets>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filterByAccessTypes: params.filterByAccessTypes,
                filterByContentType: params.filterByContentType,
                lifecycleStates:     params.lifecycleStates,
                limit:               String(params.limit),
                offset:              String(params.offset),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/recent-assets`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionAssets>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Upload User Feedback Diagnostics
         * Stores diagnostic data from user feedback in blob storage and generates a secure
         * access URL.
         */
        public async SaveDiagnostics(params: assets.DiagnosticParams): Promise<operation.Response<assets.DiagnosticResponse>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/diagnostics/save`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.DiagnosticResponse>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Search Assets by Name
         * Finds and returns assets that match the provided name search criteria.
         */
        public async SearchForAssets(params: curator.SearchForAssetsParams): Promise<operation.Response<curator.CollectionAssets>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/assets/search`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionAssets>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Share Collection With Others
         * Creates sharing permissions for a collection with specified users. Requires user
         * authentication and collection sharing authorization.
         */
        public async ShareCollection(params: curator.ShareCollectionCmdParams): Promise<operation.Response<curator.ShareCollectionResults>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/share-collections`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ShareCollectionResults>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Share Thread with Link or Email
         * Creates a permanent public access token for thread sharing, which can be used to
         * generate a shareable link or send via email.
         */
        public async ShareThread(threadID: string, params: curator.ShareThreadCmdParams): Promise<operation.Response<curator.ShareThreadResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/share`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ShareThreadResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Delete Assets (Recoverable)
         * Performs a soft delete operation on multiple assets, hiding them from queries
         * but retaining data for potential recovery. Recovery window varies based on
         * organization retention policy.
         */
        public async TombstoneAssets(params: TombstoneAssetParams): Promise<operation.Response<operations.MessageResult>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                assetIds: params.assetIds.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("DELETE", `/v1/assets/tombstone`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<operations.MessageResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Delete Threads (Recoverable)
         * Performs a soft delete operation on multiple threads, hiding them from queries
         * but retaining data for potential recovery. Recovery window varies based on
         * organization retention policy.
         */
        public async TombstoneThreads(params: TombstoneThreadParams): Promise<operation.Response<operations.MessageResult>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                threadIds: params.threadIds.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("DELETE", `/v1/threads/tombstone`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<operations.MessageResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Update Asset Properties
         * Modifies metadata and configuration attributes associated with an existing
         * asset.
         */
        public async UpdateAssetAttributes(assetID: string, params: assets.ChangeAssetAttributeCmdsParams): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/asset/${encodeURIComponent(assetID)}/attributes`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Update Asset Processing State
         * Internal endpoint for file processing services to report status changes during
         * asset processing.
         */
        public async UpdateAssetProcessingStatus(assetId: string, params: assets.OnStatusUpdateCmdParams): Promise<operation.Response<assets.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/asset-status/${encodeURIComponent(assetId)}`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<assets.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Update Collection Properties
         * Modifies metadata and configuration attributes associated with an existing
         * collection.
         */
        public async UpdateCollectionAttributes(collectionID: string, params: curator.ChangeCollectionAttributesCmdParams): Promise<operation.Response<curator.CollectionState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/collection/${encodeURIComponent(collectionID)}/attributes`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.CollectionState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Update Thread Properties
         * Modifies metadata and configuration attributes associated with an existing
         * thread.
         */
        public async UpdateThreadAttributes(threadID: string, params: curator.ChangeThreadAttributesParams): Promise<operation.Response<curator.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/attributes`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operation.Response<curator.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Handle WebSocket Connection
         * Establishes and manages a WebSocket connection for real-time communication.
         */
        public async Websocket(method: "GET", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/ws`, body, options)
        }
    }
}

export namespace ai {
    export type Model = string

    export type ModelVendor = string
}

export namespace assets {
    export type AssetCreationMethod = string

    export interface AssetMetadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        uploadedAt: string
        processing: ProcessingMetadata
        extract: ProcessingMetadata
        transcribe: ProcessingMetadata
        summarize: ProcessingMetadata
        embeddings: ProcessingMetadata
        lastReadyAt: string
        lastReferencedAt: string
        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
    }

    /**
     * AssetReference represents an individual reference to an asset while working with a thread. A new instance should be
     * created for each reference within a thread -- but, only one per thread. If the asset is still available within the
     * vendor from a previous reference the existing vendor identifier may be reused to avoid duplicating the asset within
     * the vendor's store.
     */
    export interface AssetReference {
        referenceId: stid.AssetReferenceStringID
        vendor: ai.ModelVendor
        vendorIdentifier: string
        referencedFor: stid.ThreadStringID
        referencedAt: string
        referencedBy: stid.UserStringID
    }

    /**
     * AssetSnapshot is a flattened snapshot of a specific version of an Asset.
     */
    export interface AssetSnapshot {
        id: string
        userId: string
        userDisplayName: string
        organizationId: string
        tenantId: string
        version: number
        etag: string
        originalFilename: string
        displayName: string
        filesize: number
        contentType: string
        summary: string
        lifecycleState: string
        createdAt: string
        modifiedAt: string
        processingFailureReason: string
        processingStatusText: string
        textTokens: number
        deletedAt: string
        /**
         * SignedSourceURL is generated by the controlplane to allow the client to
         * access the source asset for up to 12 hours.
         */
        signedSourceURL: string
    }

    export interface AssetState {
        metadata: AssetMetadata
        id: stid.AssetStringID
        collectionIds: stid.CollectionIDs
        /**
         * TenantID The id of the tenant the organization belongs to.
         */
        tenantId: stid.OrganizationStringID

        /**
         * OrganizationID The id of the organization the asset belongs to.
         */
        organizationId: stid.OrganizationStringID

        /**
         * Version The version of the organization.
         */
        version: number

        /**
         * ETag The entity tag for the organization. Used for cache invalidation.
         */
        etag: string

        /**
         * OriginalFileName The name of the asset as it was provided by the user originally. This is the name of the file
         * on disk when it was on the user's system.
         */
        originalFileName: string

        /**
         * DisplayName The display name of the asset. This is the name that is shown to users and may be sourced from
         * content within the asset.
         */
        displayName: string

        /**
         * LifecycleState The current lifecycle state of the asset. Assigned by the server.
         */
        lifecycleState: LifecycleState

        /**
         * LifecyclesCompleted The lifecycles that have been completed for the asset.
         */
        lifecyclesCompleted: LifecycleState[]

        /**
         * Summary The summary of the asset. This is a short description of the asset.
         * mMust be less than 2048 characters.
         */
        summary: string

        /**
         * FileSize The size of the file in bytes.
         */
        fileSize: number

        /**
         * TextTokens number of tokens in the asset's text content.
         */
        textTokens: number

        /**
         * ContentType The type of the file. This is the MIME type of the file.
         */
        contentType: string

        /**
         * SignedURL The signed URL for the asset. This is a temporary URL that can be used to access the asset.
         */
        signedURL: string

        failureReason: string
        /**
         * Embeddings The embeddings for the asset. These are generated from the asset and are used to augment conversations.
         */
        embeddings: Embeddings

        /**
         * VendorReferences is a listing of references to this asset by vendor. Vendors may retain a file only for a short
         * period of time. This is a cache of the identifiers used by the vendor to reference the asset. The validity of
         * these identifiers should be confirmed by the vendor before use and the reference dropped or updated.
         */
        references: AssetReference[]

        keywords: string[]
        /**
         * ProcessingMessage is the last message from the processing pipeline. It may be removed when processing is complete.
         */
        processingMessage: string

        /**
         * ProcessingErrMessage is the last error message from the processing pipeline. It may be removed when processing is complete.
         */
        processingErrMessage: string

        workerStrategy: WorkerStrategy
        chunkingStrategy: ChunkingStrategy
        extractionStrategy: ExtractionStrategy
        summaryStrategy: SummaryStrategy
        keywordStrategy: KeywordStrategy
        producedHTMLArtifact: boolean
        producedMarkdownArtifact: boolean
        producedTranscriptionArtifact: boolean
        producedEmbeddings: boolean
        producedSummary: boolean
        producedKeywords: boolean
        htmlArtifactTokenCount: number
        markdownTokenCount: number
        summaryTokenCount: number
        keywordsTokenCount: number
        transcriptionTokenCount: number
    }

    /**
     * ChangeAssetAttributeCmdsParams is a struct that represents the operation to rename an
     * asset. Either DisplayName or Summary (or both) can be provided. If neither
     * is provided, the asset will not be renamed and the operation will result
     * in an unprocessable entity error.
     */
    export interface ChangeAssetAttributeCmdsParams {
        assetId: stid.AssetStringID
        displayName: string
        summary: string
    }

    export type ChunkingStrategy = string

    /**
     * CreateAssetCmdParams is the params for the CreateAsset command.
     */
    export interface CreateAssetCmdParams {
        originalFileName: string
        contentType: string
        creationMethod: AssetCreationMethod
        collectionID: stid.CollectionStringID
        organizationContext: organizations.OrganizationContext
    }

    export interface DiagnosticParams {
        /**
         * Data should be JSON encoded but can be any diagnostics represented as a string
         */
        data: string
    }

    export interface DiagnosticResponse {
        url: string
    }

    export interface Embedding {
        state: EmbeddingLifecycleState
        model: string
    }

    export type EmbeddingLifecycleState = string

    export type Embeddings = Embedding[]

    export type ExtractionStrategy = string

    export type KeywordStrategy = string

    export type LifecycleState = string

    export type LifecycleStates = LifecycleState[]

    /**
     * OnStatusUpdateCmdParams is the params for the OnUploadAsset command.
     */
    export interface OnStatusUpdateCmdParams {
        status: LifecycleState
        step: string
        message: string
        jobID: stid.JobStringID
        assetID: stid.AssetStringID
        /**
         * StatusCode corresponds to the HTTP status to help identify the error.
         * and communicate it to the users.
         */
        statusCode: number

        /**
         * Summary is a short description of the asset (if available). Does not need
         * to be pushed on every update if an intermittent update provides it.
         */
        summary: string

        /**
         * DisplayName is the generated name for the asset (if available). Does not
         * need to be pushed on every update if an intermittent update provides it.
         */
        displayName: string

        /**
         * Keywords are the extracted keywords from the asset (if available). Does not
         * need to be pushed on every update if an intermittent update provides it.
         */
        keywords: string

        /**
         * TextTokens should be the token count of markdown extraction (not HTML).
         * Does not need to be pushed on every update if an intermittent update
         * provides it.
         */
        textTokens: number
    }

    /**
     * OnUploadAssetCmdParams is the params for the OnUploadAsset command.
     */
    export interface OnUploadAssetCmdParams {
        assetID: string
        /**
         * ProcessInBackground is a flag that indicates whether the asset should be processed in the background. If false,
         * the trigger to process the asset must occur through the threads domain as knowledge is added. Otherwise, the
         * system will begin processing async in the background.
         */
        processInBackground: boolean

        /**
         * ProcessInThread is a flag that indicates whether the asset should be
         * processed through a websocket message.
         */
        processInThread: boolean
    }

    export interface ProcessingMetadata {
        occurredAt: string
        startAt: string
        completedAt: string
        failedAt: string
        failedReason: string
        /**
         * http codes
         */
        statusCode: number

        step: string
        count: number
        message: string
    }

    export type SummaryStrategy = string

    export type WorkerStrategy = string
}

export namespace authz {
    /**
     * Action represents a discrete action that can be authorized.
     */
    export type Action = string

    /**
     * Actions is a collection of actions.
     */
    export type Actions = Action[]
}

export namespace curator {
    /**
     * AssetAssocFlag is a type that represents the flags that can be set on an asset association. Code is generated to
     * use bitwise operations for these flags useful for storing multiple flags in a single integer within the database.
     */
    export type AssetAssocFlag = string

    /**
     * AssetAssocFlags is a slice of AssetAssocFlag
     */
    export type AssetAssocFlags = AssetAssocFlag[]

    export interface AssetContext {
        added: stid.AssetIDs
        removed: stid.AssetIDs
    }

    /**
     * AssetContextFull is deprecated and retained for backwards compatibility.
     * Use KnowledgeProvenance instead.
     */
    export interface AssetContextFull {
        added: q.ControlplaneSsAsset[]
        removed: q.ControlplaneSsAsset[]
    }

    export type AssetRefMap = { [key: stid.AssetReferenceStringID]: AssetReference }

    /**
     * AssetReference represents a snapshot of an asset at the time of reference.
     */
    export interface AssetReference {
        /**
         * ReferenceID is the ID of the reference that this asset is associated with.
         */
        assetReferenceId: stid.AssetReferenceStringID

        collections: stid.CollectionReferenceIDs
        assetId: stid.AssetStringID
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        displayName: string
        summary: string
        modifiedAt: string
        lifecycleState: ProvenanceLifecycleState
        isCurrent: boolean
        /**
         * Version represents the AssetState at the time of reference.
         */
        version: number
    }

    export interface AssetToCollectionAssoc {
        joinId: stid.AssetColAssocStringID
        assetId: stid.AssetStringID
        collectionId: stid.CollectionStringID
    }

    export type AssetToCollectionAssocResults = AssetToCollectionAssoc[]

    /**
     * AssociateAssetsToCollectionParams represents the parameters for associating assets with a collection.
     */
    export interface AssociateAssetsToCollectionParams {
        collectionId: stid.CollectionStringID
        assetIds: stid.AssetIDs
        flags: AssetAssocFlag[]
    }

    /**
     * AssociateThreadsToCollectionParams represents the parameters for associating threads with a collection.
     */
    export interface AssociateThreadsToCollectionParams {
        collectionId: stid.CollectionStringID
        threadIds: stid.ThreadIDs
        flags: ThreadAssocFlag[]
    }

    export interface AssociatedEntity<T, F> {
        data: T
        collectionIds: stid.CollectionIDs
        flags: F[]
        actions: authz.Action[]
    }

    export interface AuthzEnvelope<T> {
        data: T
        actions: authz.Action[]
    }

    /**
     * ChangeCollectionAttributesCmdParams is a struct that represents the operation to rename
     * a collection. Label or Description (or both) can be provided. If neither is
     * provided, the collection will not be renamed and the operation will result in
     * an unprocessable entity error.
     */
    export interface ChangeCollectionAttributesCmdParams {
        collectionId: stid.CollectionStringID
        label: string
        description: string
        icon: string
    }

    /**
     * ChangeThreadAttributesParams is a struct that represents the operation to rename a
     * thread. Label must be provided. If it is not provided, the thread will not be
     * renamed and the operation will result in an unprocessable entity error.
     */
    export interface ChangeThreadAttributesParams {
        threadId: stid.ThreadStringID
        label: string
    }

    /**
     * Citation represents a citation -- the exact content -- sourced from an asset.
     */
    export interface Citation {
        citationId: stid.CitationStringID
        assetReferenceId: stid.AssetReferenceStringID
        chunkId: string
        position: number
        text: string
        /**
         * Relevance is the relevance of the result to the question being asked.
         * A value of (0) or close to (0) reflects that an exact match occurred.
         * The closer to zero (0) the more relevant the result is to the question that
         * was asked.
         */
        relevance: number
    }

    export type CitationMap = { [key: stid.CitationStringID]: Citation }

    export interface ClaimCollectionResult {
        successfulGrants: stid.CollectionIDs
        unsuccessfulGrants: FailedClaims[]
        trees: CollectionTrees
    }

    /**
     * CollectionAccessToken represents a token that grants temporary access to one
     * or more collections
     */
    export interface CollectionAccessToken {
        issuedByUserId: stid.UserStringID
        inviteeEmail: string
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        grants: CollectionGrant[]
        issuedByDisplayName: string
        publicToken: string
        token: stid.TokenStringID
        issuedAt: string
    }

    export interface CollectionAssets {
        result: CollectionEntities<assets.AssetSnapshot, AssetAssocFlags>
        pagination: pagination.Metadata
    }

    /**
     * CollectionEntities represents the state of a collection and the desired kind of entities within it.
     */
    export interface CollectionEntities<T, F> {
        collections: { [key: stid.CollectionStringID]: AuthzEnvelope<CollectionSnapshot> }
        entities: AssociatedEntity<T, F>[]
    }

    /**
     * CollectionGrant represents a single grant of access to a collection with a
     * specific action
     */
    export interface CollectionGrant {
        collectionId: stid.CollectionStringID
        action: string
    }

    export type CollectionKind = string

    export type CollectionLifecycleState = string

    export type CollectionRefMap = { [key: stid.CollectionReferenceStringID]: CollectionReference }

    /**
     * CollectionReference represents a snapshot of a collection at the time of
     * reference.
     */
    export interface CollectionReference {
        collectionReferenceId: stid.CollectionReferenceStringID
        collectionId: stid.CollectionStringID
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        label: string
        description: string
        path: stid.CollectionIDs
        lifecycleState: ProvenanceLifecycleState
        isCurrent: boolean
        modifiedAt: string
        /**
         * Version represents the CollectionState at the time of reference.
         */
        version: number
    }

    export interface CollectionSnapshot {
        id: stid.CollectionStringID
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        collectionKind: CollectionKind
        lifecycleState: CollectionLifecycleState
        icon: string
        label: string
        description: string
        version: number
        etag: string
        path: stid.CollectionIDs
    }

    export interface CollectionState {
        id: stid.CollectionStringID
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        collectionKind: CollectionKind
        icon: string
        label: string
        description: string
        lifecycleState: CollectionLifecycleState
        metadata: Metadata
        version: number
        etag: string
        path: stid.CollectionIDs
    }

    export interface CollectionThreads {
        result: CollectionEntities<ThreadSnapshot, ThreadAssocFlags>
        pagination: pagination.Metadata
    }

    export interface CollectionTree {
        collection: CollectionSnapshot
        children?: CollectionTrees
    }

    export type CollectionTrees = CollectionTree[]

    /**
     * CreateCollectionCmdParams is the params for the CreateCollection command.
     */
    export interface CreateCollectionCmdParams {
        label: string
        description: string
        icon: string
        kind: CollectionKind
        parent: stid.CollectionStringID
        organizationContext: organizations.OrganizationContext
    }

    /**
     * CreateThreadCmdParams is the params for the CreateThread command.
     */
    export interface CreateThreadCmdParams {
        /**
         * CollectionID is the collection that the thread will be created in.
         * If CollectionIDsInScope is populated, the thread will not automatically
         * be scoped to this collection.
         */
        collectionId: stid.CollectionStringID

        organizationContext: organizations.OrganizationContext
        label: string
        /**
         * AssetIDs is a list of assets that have been chosen by the user to be in
         * the initial scope of the thread.
         */
        assetIds: stid.AssetIDs

        /**
         * CollectionIDsInScope is a list of collections that have been chosen by the
         * user to be in the initial scope of the thread. If populated, the thread
         * will not automatically inherit the collection the thread is created in.
         */
        collectionIds: stid.CollectionIDs
    }

    export interface FailedClaims {
        collectionId: stid.CollectionStringID
        granted: boolean
        failureReason: string
    }

    export interface GetCollectionAccessRecord {
        "user_id": stid.UserStringID
        email: string
        sortableName: string
        pictureURL: string
        "collection_id": stid.CollectionStringID
        collectionLabel: string
        collectionDescription: string
        collectionPath: stid.CollectionIDs
        actions: authz.Actions
        token: stid.TokenStringID
        publicToken: string
        tokenIssuedAt: string
        tokenGrantedBy: string
        /**
         * AccessType is a discriminator field to denote if this record is for a
         * user or a token.
         */
        accessType: string
    }

    /**
     * GetCollectionAccessResult is the output of GetCollectionAccess. Records is
     * a blend of registered users and people who have tokens issues but have not
     * yet accepted them.
     */
    export interface GetCollectionAccessResult {
        records: GetCollectionAccessRecord[]
        pagination: pagination.Metadata
    }

    /**
     * Knowledge is used to communicate changes in the context made available for
     * this message. Only differences should be defined. All future messages will
     * retain these adjustments until future instructions are provided to change the
     * context.
     */
    export interface Knowledge {
        assetContext: AssetContext
        organizationContext: OrganizationContext
        world: boolean
    }

    /**
     * KnowledgeFull is deprecated and retained for backwards compatibility.
     * Use KnowledgeProvenance instead.
     * 
     * KnowledgeFull is used to communicate changes in the context made available
     * for this message. Only differences should be defined. All future messages
     * will retain these adjustments until future instructions are provided to
     * change the context. This type is similar to Knowledge but includes the full
     * state of knowledge and is returned for knowledge events to aid the UI in
     * printing human friendly data.
     */
    export interface KnowledgeFull {
        assetContext: AssetContextFull
        world: boolean
    }

    /**
     * KnowledgeProvenance tracks the knowledge sources and references used within a thread
     * of conversation. It maintains a complete history of all collections, assets,
     * and citations that were referenced, including their state at the time of
     * reference. This allows for accurately recreating or disclosing what knowledge
     * was available and used at any point in the conversation, even if the
     * underlying data has since changed or been deleted.
     * 
     * The maps in this structure serve as efficient lookup tables:
     * - Collections: Track referenced collection versions and their states
     * - Assets: Track referenced asset versions and their states
     * - Citations: Track specific citations to chunks within assets
     * - Messages: Track the scope and usage of knowledge in each message
     */
    export interface KnowledgeProvenance {
        /**
         * CollectionRefs is a map of all collections either directly referenced or
         * of which an asset belongs to. It can be used to quickly look up the
         * a collection.
         * 
         * It is possible the same collection is referenced multiple
         * times--once for each version of the collection if it was referenced
         * over time or has since changed. For example, if a collection referenced
         * previously and has since been renamed there would be one version of the
         * collection as it was when it was referenced and another version for the
         * current state.
         * 
         * When a collection was previously referenced but no longer exists the latest
         * references will reflect the deleted status in the lifecycle state.
         */
        collectionRef: CollectionRefMap

        /**
         * AssetRefs maps every asset referenced. It can be used to quickly look up
         * an asset. It is possible multiple versions of the same asset are
         * referenced if the asset was updated over time. If an asset was
         * referenced previously but no longer exists the latest reference will reflect
         * the deleted status in their lifecycle state.
         */
        assetRef: AssetRefMap

        /**
         * Citations maps every citation referenced. It can be used to quickly look up
         * a citation which then references a specific asset.
         */
        citations: CitationMap

        /**
         * Scope is the latest scope of knowledge used in the conversation.
         * If no changes are made this will be sent to the model for the next message
         * and is an easy way to communicate the current scope of the conversation.
         */
        inScope: Scope
    }

    export type LifecycleState = string

    /**
     * Mentions provides the means to communicate collection(s) or asset(s) that are
     * grouped together due to explicit referencing via a '#', '@', or other means
     * in the UI. The decision to use an array of arrays is to support the use case
     * where a user wants to compare A and B against C or otherwise group up the
     * asset(s) or collection(s) being "mentioned".
     */
    export interface Mentions {
        /**
         * Assets is an array of stid.ID[stid.Asset] arrays. Exists to allow for
         * comparing or otherwise wanting to reference N number of grouped
         * assets together.
         */
        assets: stid.AssetIDs[]

        /**
         * Collections is an array of stid.[stid.Collection] arrays. Exists to
         * allow for comparing or otherwise wanting to reference N number of
         * grouped collections together.
         */
        collections: stid.CollectionIDs[]
    }

    export interface Message {
        id: stid.MessageStringID
        threadId: stid.ThreadStringID
        metadata: Metadata
        version: number
        createdBy: stid.UserStringID
        isComplete: boolean
        recipients: Recipient[]
        worldKnowledge: boolean
        /**
         * Content is a base64 encoded JSON string that can be decoded.
         * Reference kind to determine how to decode.
         */
        content: string

        kind: MessageKind
        transformationQueue: string[]
        transformations: string[]
        textSuggestions: string[]
    }

    /**
     * MessageCmdParams is the params for the ReceiveMessage command.
     */
    export interface MessageCmdParams {
        threadId: stid.ThreadStringID
        /**
         * MessageContent is a base64 encoded JSON string that can be decoded. Reference kind to determine how to decode.
         */
        messageContent: string

        messageKind: MessageKind
        recipients: Recipient[]
    }

    export type MessageKind = string

    /**
     * MessageKinds represents any of the content kinds.
     * 
     * The primary use of this struct is to register all types so encore can
     * generate types for SDKs.
     * 
     * Note: There is an additional message kind `Error` which is not generated and
     * conforms to the error type returned by Encore.
     */
    export interface MessageKinds {
        promptCampaignV1: MessagePromptTransformationV1
        promptCampaignV2: MessagePromptTransformationV2
        promptV1: MessagePromptV1
        promptV2: MessagePromptV2
        textV1: MessageTextV1
        knowledgeChangeV1: MessageKnowledgeV1
        renameThreadV1: MessageRenameThreadV1
    }

    /**
     * MessageKnowledgeV1 is deprecated and should not be used.
     */
    export interface MessageKnowledgeV1 {
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        threadId: stid.ThreadStringID
        messageId: stid.MessageStringID
        kind: MessageKind
        knowledgeFull: KnowledgeFull
        knowledge: Knowledge
        createdBy: stid.UserStringID
        progress: { [key: string]: ProcessingStatus }
    }

    export interface MessagePromptTransformationV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        prompt: string
        knowledge: Knowledge
        model: ai.Model
        transformationId: string
        createdBy: stid.UserStringID
    }

    export interface MessagePromptTransformationV2 {
        kind: MessageKind
        messageId: stid.MessageStringID
        prompt: string
        scope: Scope
        model: ai.Model
        transformationId: string
        createdBy: stid.UserStringID
    }

    /**
     * MessagePromptV1 is deprecated and should not be used. It is kept here for
     * backwards compatibility of threads prior to the introduction of collections.
     */
    export interface MessagePromptV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        /**
         * ThreadID stid.ID[stid.Thread] `json:"threadId"`
         */
        prompt: string

        knowledge: Knowledge
        model: ai.Model
        transformationId: string
        temperature: number
        "max_tokens": number
        "top_p": number
        "frequency_penalty": number
        "presence_penalty": number
        stop: string[]
        "logit_bias": { [key: number]: number }
        "context_window": number
        createdBy: stid.UserStringID
    }

    /**
     * MessagePromptV2 is a message received from the client via a websocket
     * to prompt a model to generate a response.
     */
    export interface MessagePromptV2 {
        kind: MessageKind
        messageId: stid.MessageStringID
        prompt: string
        scope: Scope
        model: ai.Model
        transformationId: string
        temperature: number
        "max_tokens": number
        "top_p": number
        "frequency_penalty": number
        "presence_penalty": number
        stop: string[]
        "logit_bias": { [key: number]: number }
        "context_window": number
        createdBy: stid.UserStringID
    }

    export interface MessageRenameThreadV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        label: string
        createdBy: stid.UserStringID
    }

    export interface MessageTextV1 {
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        threadId: stid.ThreadStringID
        messageId: stid.MessageStringID
        kind: MessageKind
        parts: string[]
        provenance: Provenance
        isDone: boolean
        label: string
        textSuggestions: string[]
        createdBy: stid.UserStringID
    }

    export interface Metadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        /**
         * DeletedBy communicates the ID of the user who deleted the thread.
         */
        deletedBy: stid.UserStringID
    }

    export interface ModifyCollectionPermissionsParams {
        collectionId: stid.CollectionStringID
        userId: stid.UserStringID
        actions: authz.Actions
    }

    /**
     * MoveCollectionArguments is a struct that represents the operation
     * to move a collection from one parent to another.
     */
    export interface MoveCollectionArguments {
        /**
         * CollectionID is the ID of the collection to move.
         */
        collectionId: stid.CollectionStringID

        /**
         * ParentCollectionID is the ID of the new parent collection to move the
         * collection to.
         */
        parentCollectionId: stid.CollectionStringID
    }

    export interface OrganizationContext {
        added: stid.OrganizationIDs
        removed: stid.OrganizationIDs
    }

    export interface ProcessingStatus {
        percentage: number
        message: string
        lifecycleState: assets.LifecycleState
        isErr: boolean
        isDone: boolean
    }

    /**
     * EnhancedPrompt represents the enhanced version of the prompt.
     */
    export interface PromptEnhanced {
        content: string[]
        summary: string[]
    }

    /**
     * PromptEnhancementsCmdParams represents the endpoint/API parameters for the prompt enhancement command.
     */
    export interface PromptEnhancementsCmdParams {
        /**
         * Type is the type of prompt enhancement strategy.
         */
        type: PromptEnhancementsType

        /**
         * Prompt is the prompt to enhance.
         */
        prompt: string
    }

    /**
     * PromptEnhancementsResponse represents the response from the PromptEnhance function.
     */
    export interface PromptEnhancementsResponse {
        "prompt_enhanced": PromptEnhanced
    }

    /**
     * PromptEnhancementsType represent the strategy/type of prompt enhancement.
     */
    export type PromptEnhancementsType = string

    /**
     * Provenance represents the provenance of a message which represents custody
     * of data and data about how it was processed.
     */
    export interface Provenance {
        processedByModel: ai.Model
        suggestedModels: SuggestedModel[]
        transformationId: string
        outputTokens: number
        inputTokens: number
        scoped: Scoped
    }

    export type ProvenanceLifecycleState = string

    export type Recipient = string

    export interface Scope {
        /**
         * WorldKnowledge is a flag that indicates whether general knowledge for the
         * LLM was used in the message. This is used to indicate that the message
         * may have included information that was not directly referenced in the
         * conversation.
         */
        worldKnowledge: boolean

        /**
         * InScopeCollections is a list of collections that were considered to be
         * in scope for the message. If a parent was selected and it's children
         * included every descendant collection is listed to be explicit about
         * what was considered in scope.
         */
        collectionIDs: stid.CollectionIDs

        /**
         * AssetIDs is a list of every asset a user explicitly put into scope.
         */
        assetIDs: stid.AssetIDs

        /**
         * Mentions exists to support the '#' or '@' references whereby a user
         * has explicitly stated that they want to perform a prompt against
         * certain assets or comparing assets.
         */
        mentions: Mentions
    }

    /**
     * Scoped represents the applied scope of knowledge used in a message.
     */
    export interface Scoped {
        /**
         * WorldKnowledge is a flag that indicates whether general knowledge for the
         * LLM was used in the message. This is used to indicate that the message
         * may have included information that was not directly referenced in the
         * conversation.
         */
        worldKnowledge: boolean

        /**
         * InScopeCollections is a list of collections that were considered to be
         * in scope for the message. If a parent was selected and it's children
         * included every descendant collection is listed to be explicit about
         * what was considered in scope.
         */
        collectionReferenceIDs: stid.CollectionReferenceIDs

        /**
         * InScopeAssets is a list of every asset a user explicitly put into scope.
         * If a collection was selected and assets were referenced from the collection
         * scope they will not appear here unless the user specifically added them
         * to scope.
         */
        explicitAssets: stid.AssetReferenceIDs

        /**
         * CollectionAssets is a list of every asset included in scope through
         * including a collection. It is possible that a asset is included both in
         * this list and the explicit assets list if the asset was explicitly added
         * to scope and was also ranked highly in a query that included a collection.
         */
        collectionAssets: stid.AssetReferenceIDs

        /**
         * Citations is a list of every citation made available to the model
         * with the exception of world knowledge.
         */
        citations: stid.CitationIDs
    }

    /**
     * SearchForAssetsParams is the input parameters for the SearchForAssets method.
     */
    export interface SearchForAssetsParams {
        pagination: pagination.Params
        /**
         * MatchName, optional, constrains the search to assets that have a name
         * that matches the specified string. If omitted, the system will return
         * assets that may be relevant to the user.
         */
        matchName: string

        /**
         * FilterByLifecycleStates, optional, constrains the search to assets that are
         * in the specified lifecycle states.
         */
        lifecycleStates: assets.LifecycleStates

        /**
         * FilterByCollections, optional, constrains the search to assets that are
         * part of the specified collections or their sub-collections.
         */
        filterByCollections: stid.CollectionIDs

        /**
         * FilterByContentType, optional, constrains the search to assets that are
         * of a specific content type.
         */
        filterByContentType: mime.ContentTypes
    }

    export interface ShareCollectionCmdParams {
        collectionIds: stid.CollectionIDs
        shareTo: stid.UserIDs
        shareToEmails: string[]
        actions: authz.Action[]
    }

    export interface ShareCollectionResults {
        newUsers: CollectionAccessToken[]
        existingUsers: userlookup.UserSnapshot[]
    }

    /**
     * ShareMethod represents the method of sharing a thread.
     */
    export type ShareMethod = string

    /**
     * ShareThreadCmdParams is the params for the ShareThread command.
     */
    export interface ShareThreadCmdParams {
        threadId: stid.ThreadStringID
        /**
         * MessageID is the ID of a message to deep link to. If not provided,
         * no deep linking will be performed. If the message is not found, an error
         * will be returned.
         */
        messageId: stid.MessageStringID

        shareMethod: ShareMethod
        shareTo: string[]
    }

    export interface ShareThreadResult {
        tokenId: stid.TokenStringID
        publicToken: string
        address: string
    }

    /**
     * SuggestedModel represents a model suggestion with its confidence score
     */
    export interface SuggestedModel {
        model: ai.Model
        score: number
        category: string
    }

    /**
     * ThreadAssocFlag is a type that represents the flags that can be set on an asset association. Code is generated to
     * use bitwise operations for these flags useful for storing multiple flags in a single integer within the database.
     */
    export type ThreadAssocFlag = string

    /**
     * ThreadAssocFlags is a slice of ThreadAssocFlag
     */
    export type ThreadAssocFlags = ThreadAssocFlag[]

    export interface ThreadShare {
        tokenId: stid.TokenStringID
        sharedBy: stid.UserStringID
        sharedByDisplayName: string
        sharedAt: string
        publicToken: string
        sharedToEmails: string[]
        shareMethod: ShareMethod
        messageId: stid.MessageStringID
    }

    export interface ThreadSnapshot {
        threadId: stid.ThreadStringID
        label: string
        createdBy: stid.UserStringID
        createdAt: string
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        etag: string
        version: number
        messageCount: number
        lifecycleState: LifecycleState
        modifiedAt: string
    }

    export interface ThreadState {
        threadId: stid.ThreadStringID
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        label: string
        metadata: Metadata
        messages: Message[]
        lifecycleState: LifecycleState
        etag: string
        version: number
        activeAssets: q.ControlplaneSsAsset[]
        collectionIDs: stid.CollectionIDs
        knowledgeProvenance: KnowledgeProvenance
        permanentShareToken: ThreadShare[]
    }

    export interface ThreadToCollectionAssoc {
        joinId: stid.ThreadColAssocStringID
        threadId: stid.ThreadStringID
        collectionId: stid.CollectionStringID
    }

    export type ThreadToCollectionAssocResults = ThreadToCollectionAssoc[]
}

export namespace mime {
    /**
     * ContentType represents the type of content that can be uploaded.
     */
    export type ContentType = string

    export type ContentTypes = ContentType[]
}

export namespace operation {
    export interface Consumption {
        units: number
    }

    export interface Details {
        operation: ResponseOperation
        consumption: Consumption
    }

    export interface Response<T> {
        OperationID: string
        Duration: string
        eventKey: string
        code: string
        message: string
        details: Details
        data: T
        done: boolean
    }

    export interface ResponseOperation {
        operationId: string
        duration: number
    }
}

export namespace operations {
    /**
     * MessageResult is a struct that represents the result of an operation that
     * returns only a message.
     */
    export interface MessageResult {
        message: string
    }
}

export namespace organizations {
    /**
     * OrganizationContext represents the context of an organization.
     */
    export interface OrganizationContext {
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
    }
}

export namespace pagination {
    /**
     * Metadata contains pagination metadata for use in returning results.
     */
    export interface Metadata {
        count: number
        limit: number
        offset: number
        currentPage: number
        totalPages: number
        hasNextPage: boolean
        hasPreviousPage: boolean
        firstRecordOnPage: string
        lastRecordOnPage: string
    }

    /**
     * Params provides basic pagination params that are common across packages in
     * the Storytell repo.
     */
    export interface Params {
        /**
         * Limit is the number of items to return.
         */
        Limit: number

        /**
         * Offset is the number of items to skip.
         */
        Offset: number

        /**
         * Sort is a CSV of fields to sort by. Prefix with "-" for descending
         * order.
         */
        Sort: string[]
    }
}

export namespace q {
    export interface ControlplaneSsAsset {
        id: string
        userId: string
        userDisplayName: string
        projectId: string
        organizationId: string
        tenantId: string
        version: number
        etag: string
        originalFilename: string
        displayName: string
        filesize: number
        contentType: string
        summary: string
        lifecycleState: string
        createdAt: string
        modifiedAt: string
        processingFailureReason: sql.NullString
        processingStatusText: sql.NullString
        textTokens: number
        deletedAt: sql.NullTime
    }
}

export namespace session {
    export interface Attribution {
        ProxiedBy: stid.UserStringID
        TenantID: stid.OrganizationStringID
        OrganizationID: stid.OrganizationStringID
    }

    /**
     * Authenticator is the type of authenticator that is used to authenticate
     * the user.
     */
    export type Authenticator = string

    export interface Session {
        ID: stid.UserStringID
        ExternalAuthID: string
        ExternalAuthSystem: Authenticator
        DisplayName: string
        Email: string
        IsEmailVerified: boolean
        PictureURL: string
        IdentityToken: string
        Attribution: Attribution
        HasCustomClaims: boolean
    }
}

export namespace slugs {
    export type Slug = string
}

export namespace sql {
    /**
     * NullString represents a string that may be null.
     * NullString implements the [Scanner] interface so
     * it can be used as a scan destination:
     * 
     * 	var s NullString
     * 	err := db.QueryRow("SELECT name FROM foo WHERE id=?", id).Scan(&s)
     * 	...
     * 	if s.Valid {
     * 	   // use s.String
     * 	} else {
     * 	   // NULL value
     * 	}
     */
    export interface NullString {
        String: string
        /**
         * Valid is true if String is not NULL
         */
        Valid: boolean
    }

    /**
     * NullTime represents a [time.Time] that may be null.
     * NullTime implements the [Scanner] interface so
     * it can be used as a scan destination, similar to [NullString].
     */
    export interface NullTime {
        Time: string
        /**
         * Valid is true if Time is not NULL
         */
        Valid: boolean
    }
}

export namespace status {
    export interface Memory {
        rss: string
        vms: string
    }

    export interface Response {
        status: string
        bornAt: string
        memory: Memory
        goRoutines: number
    }
}

export namespace stid {
    /**
     * Asset is a type safe ID.
     */
    export interface Asset {
    }

    /**
     * AssetColAssoc is a type safe ID.
     */
    export interface AssetColAssoc {
    }

    /**
     * AssetColAssocStringID is a string type for the AssetColAssoc ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type AssetColAssocStringID = string

    /**
     * AssetIDs is a slice of Asset IDs.
     */
    export type AssetIDs = AssetStringID[]

    /**
     * AssetReference is a type safe ID.
     */
    export interface AssetReference {
    }

    /**
     * AssetReferenceIDs is a slice of AssetReference IDs.
     */
    export type AssetReferenceIDs = AssetReferenceStringID[]

    /**
     * AssetReferenceStringID is a string type for the AssetReference ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type AssetReferenceStringID = string

    /**
     * AssetStringID is a string type for the Asset ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type AssetStringID = string

    /**
     * Bag is a collection of ID types. It is mainly used to influence the encore generator to generate the
     * the relevant string types.
     */
    export interface Bag {
        UserStringID: UserStringID
        OrganizationStringID: OrganizationStringID
        ProjectStringID: ProjectStringID
        DeviceStringID: DeviceStringID
        OperationStringID: OperationStringID
        EventStringID: EventStringID
        AssetStringID: AssetStringID
        DirectoryStringID: DirectoryStringID
        TeamStringID: TeamStringID
        PermissionStringID: PermissionStringID
        InteractionStringID: InteractionStringID
        SkuStringID: SkuStringID
        RequestStringID: RequestStringID
        TagStringID: TagStringID
        ChunkStringID: ChunkStringID
        ThreadStringID: ThreadStringID
        MessageStringID: MessageStringID
        StepStringID: StepStringID
        AssetReferenceStringID: AssetReferenceStringID
        KeywordStringID: KeywordStringID
        HistoryStringID: HistoryStringID
        DiagnosticStringID: DiagnosticStringID
        MessageStepStringID: MessageStepStringID
        CollectionStringID: CollectionStringID
        ThreadColAssocStringID: ThreadColAssocStringID
        AssetColAssocStringID: AssetColAssocStringID
        JobStringID: JobStringID
        CollectionReferenceStringID: CollectionReferenceStringID
        CitationStringID: CitationStringID
        TokenStringID: TokenStringID
        RecentStringID: RecentStringID
    }

    /**
     * ChunkStringID is a string type for the Chunk ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ChunkStringID = string

    /**
     * Citation is a type safe ID.
     */
    export interface Citation {
    }

    /**
     * CitationIDs is a slice of Citation IDs.
     */
    export type CitationIDs = CitationStringID[]

    /**
     * CitationStringID is a string type for the Citation ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type CitationStringID = string

    /**
     * Collection is a type safe ID.
     */
    export interface Collection {
    }

    /**
     * CollectionIDs is a slice of Collection IDs.
     */
    export type CollectionIDs = CollectionStringID[]

    /**
     * CollectionReference is a type safe ID.
     */
    export interface CollectionReference {
    }

    /**
     * CollectionReferenceIDs is a slice of CollectionReference IDs.
     */
    export type CollectionReferenceIDs = CollectionReferenceStringID[]

    /**
     * CollectionReferenceStringID is a string type for the CollectionReference ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type CollectionReferenceStringID = string

    /**
     * CollectionStringID is a string type for the Collection ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type CollectionStringID = string

    /**
     * DeviceStringID is a string type for the Device ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DeviceStringID = string

    /**
     * DiagnosticStringID is a string type for the Diagnostic ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DiagnosticStringID = string

    /**
     * DirectoryStringID is a string type for the Directory ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DirectoryStringID = string

    /**
     * EventStringID is a string type for the Event ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type EventStringID = string

    /**
     * HistoryStringID is a string type for the History ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type HistoryStringID = string

    export type ID<T> = xid.ID

    /**
     * InteractionStringID is a string type for the Interaction ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type InteractionStringID = string

    /**
     * Job is a type safe ID.
     */
    export interface Job {
    }

    /**
     * JobStringID is a string type for the Job ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type JobStringID = string

    /**
     * KeywordStringID is a string type for the Keyword ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type KeywordStringID = string

    /**
     * Message is a type safe ID.
     */
    export interface Message {
    }

    /**
     * MessageStepStringID is a string type for the MessageStep ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type MessageStepStringID = string

    /**
     * MessageStringID is a string type for the Message ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type MessageStringID = string

    /**
     * OperationStringID is a string type for the Operation ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type OperationStringID = string

    /**
     * Organization is a type safe ID.
     */
    export interface Organization {
    }

    /**
     * OrganizationIDs is a slice of Organization IDs.
     */
    export type OrganizationIDs = OrganizationStringID[]

    /**
     * OrganizationStringID is a string type for the Organization ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type OrganizationStringID = string

    /**
     * PermissionStringID is a string type for the Permission ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type PermissionStringID = string

    /**
     * ProjectStringID is a string type for the Project ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ProjectStringID = string

    /**
     * RecentStringID is a string type for the Recent ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type RecentStringID = string

    /**
     * RequestStringID is a string type for the Request ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type RequestStringID = string

    /**
     * SkuStringID is a string type for the Sku ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type SkuStringID = string

    /**
     * StepStringID is a string type for the Step ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type StepStringID = string

    /**
     * TagStringID is a string type for the Tag ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type TagStringID = string

    /**
     * TeamStringID is a string type for the Team ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type TeamStringID = string

    /**
     * Thread is a type safe ID.
     */
    export interface Thread {
    }

    /**
     * ThreadColAssoc is a type safe ID.
     */
    export interface ThreadColAssoc {
    }

    /**
     * ThreadColAssocStringID is a string type for the ThreadColAssoc ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ThreadColAssocStringID = string

    /**
     * ThreadIDs is a slice of Thread IDs.
     */
    export type ThreadIDs = ThreadStringID[]

    /**
     * ThreadStringID is a string type for the Thread ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ThreadStringID = string

    /**
     * Token is a type safe ID.
     */
    export interface Token {
    }

    /**
     * TokenStringID is a string type for the Token ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type TokenStringID = string

    /**
     * User is a type safe ID.
     */
    export interface User {
    }

    /**
     * UserIDs is a slice of User IDs.
     */
    export type UserIDs = UserStringID[]

    /**
     * UserStringID is a string type for the User ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type UserStringID = string
}

export namespace userlookup {
    /**
     * UserSnapshot is a snapshot of frequently used user state used to return
     * lists of users.
     */
    export interface UserSnapshot {
        id: string
        etag: string
        primaryEmail: string
        displayName: string
        pictureUrl: string
        lifecycleState: string
        potentialScore: number
        version: number
        createdAt: string
        modifiedAt: string
    }
}

export namespace users {
    export interface Address {
        /**
         * Formatted is the unstructured value of the address. If this is not set by
         * the user it will be automatically constructed from structured values.
         */
        formatted: string

        poBox: string
        streetAddress: string
        extendedAddress: string
        city: string
        region: string
        postalCode: string
        country: string
        countryCode: string
        /**
         * Type The type of the email address and can be:
         * - `work` A work email address.
         * - `home` A personal home email address.
         * - `school` A school email address.
         * - `other` A custom email address not of the above types.
         */
        type: string

        metadata: Metadata
    }

    export interface AuthSyncResult {
        user: UserState
        workingContext: WorkingContext
        tokenRefreshRequired: boolean
        tokenRefreshReason: TokenRefreshReason
        didSignUp: boolean
        didClaimInvites: boolean
        didDetectChanges: boolean
    }

    export interface EmailAddress {
        email: string
        /**
         * Type The type of the email address and can be:
         * - `work` A work email address.
         * - `home` A personal home email address.
         * - `school` A school email address.
         * - `other` A custom email address not of the above types.
         * - 'default' The default classification for an email address.
         */
        type: string

        verifiedAt: string
    }

    export interface ExternalIdentifier {
        id: string
        label: string
        metadata: Metadata
    }

    export interface HealSnapshotResult {
        CountAttempted: number
        CountHealed: number
        TotalRecords: number
        Errors: string[]
        Processed: string[]
    }

    export type LifecycleState = string

    export type MaintenanceActivities = MaintenanceActivity[]

    /**
     * MaintenanceActivity represents a maintenance activity that has be applied
     * to a user and is stored in UserState.
     */
    export interface MaintenanceActivity {
        activity: MaintenanceActivityKey
        "applied_at": string
    }

    export type MaintenanceActivityKey = string

    /**
     * MergeUserAssetChanges tracks all asset-related changes during a user merge
     * operation
     */
    export interface MergeUserAssetChanges {
        /**
         * References contains asset IDs from dat_asset_reference_user where user_id
         * was updated
         */
        references: string[]

        /**
         * Assets contains asset IDs from ss_assets where user_id ownership was
         * transferred
         */
        assets: string[]

        /**
         * Recent contains asset IDs from dat_recent_assets where user_id was updated
         */
        recent: string[]
    }

    /**
     * MergeUserAssetJoinChange represents updates to an asset-collection
     * join record
     */
    export interface MergeUserAssetJoinChange {
        /**
         * JoinID is the unique identifier for this asset-collection association
         */
        "join_id": string

        /**
         * CreatedBy contains the new user ID that replaced the old user ID in the
         * created_by field
         */
        "created_by": string

        /**
         * ModifiedBy contains the new user ID that replaced the old user ID in the
         * modified_by field
         */
        "modified_by": string

        /**
         * DeletedBy contains the new user ID if the old user ID was found in the
         * deleted_by field
         */
        "deleted_by": string
    }

    /**
     * MergeUserCollectionChange represents updates to collection metadata during
     * a merge
     */
    export interface MergeUserCollectionChange {
        /**
         * ID is the unique identifier of the updated collection
         */
        id: string

        /**
         * CreatedBy contains the new user ID that replaced the old user ID
         */
        "created_by": string

        /**
         * LastChatBy contains the new user ID if old user ID was in last_chat_by
         */
        "last_chat_by": string

        /**
         * LastAssetBy contains the new user ID if old user ID was in last_asset_by
         */
        "last_asset_by": string
    }

    /**
     * MergeUserCollectionPathChange represents a change to a collection's path
     * during a merge operation
     */
    export interface MergeUserCollectionPathChange {
        /**
         * CollectionID is the unique identifier of the collection whose path was updated
         */
        "collection_id": string

        /**
         * OldPath is the original ltree path of the collection before the merge
         */
        "old_path": string

        /**
         * NewPath is the updated ltree path of the collection after the merge
         */
        "new_path": string
    }

    /**
     * MergeUserJoinTableChanges represents updates to association tables during
     * a merge
     */
    export interface MergeUserJoinTableChanges {
        /**
         * AssetsToCol contains all asset join records that were updated
         */
        "assets_to_col": MergeUserAssetJoinChange[]

        /**
         * ThreadsToCol contains all thread join records that were updated
         */
        "threads_to_col": MergeUserThreadJoinChange[]
    }

    /**
     * MergeUserPermissionChanges represents permission changes during a user merge
     */
    export interface MergeUserPermissionChanges {
        /**
         * Deleted contains permissions that were removed due to duplication
         */
        deleted: MergeUserPermissionDelete[]

        /**
         * Updated contains permissions where user_id or created_by were updated
         */
        updated: MergeUserPermissionUpdate[]
    }

    /**
     * MergeUserPermissionDelete represents a permission that was deleted due to
     * duplication
     */
    export interface MergeUserPermissionDelete {
        /**
         * UserID is the old user ID whose permission was deleted
         */
        "user_id": string

        /**
         * CollectionID is the collection where the permission was deleted
         */
        "collection_id": string

        /**
         * Action is the permission action that was deleted
         */
        action: string
    }

    /**
     * MergeUserPermissionUpdate represents a permission that was updated during
     * merge
     */
    export interface MergeUserPermissionUpdate {
        /**
         * UserID is the new user ID that replaced the old user ID
         */
        "user_id": string

        /**
         * CollectionID is the collection where the permission was updated
         */
        "collection_id": string

        /**
         * Action is the permission action that was kept
         */
        action: string

        /**
         * CreatedBy contains the new user ID if it was updated in created_by
         */
        "created_by": string
    }

    /**
     * MergeUserResults represents all changes made during a user merge operation
     */
    export interface MergeUserResults {
        /**
         * CollectionPaths contains all collection path updates made during the merge
         */
        collectionPaths: MergeUserCollectionPathChange[]

        /**
         * WorkingContext contains working contexts that were deleted due to conflicts
         */
        workingContext: MergeUserWorkingContextChange[]

        /**
         * Assets contains all asset-related changes made during the merge
         */
        assets: MergeUserAssetChanges

        /**
         * Threads contains all thread-related changes made during the merge
         */
        threads: MergeUserThreadChanges

        /**
         * Collections contains all collection metadata updates made during the merge
         */
        collections: MergeUserCollectionChange[]

        /**
         * Teams contains all team metadata updates made during the merge
         */
        teams: MergeUserTeamChange[]

        /**
         * Permissions contains all permission changes made during the merge
         */
        permissions: MergeUserPermissionChanges

        /**
         * JoinTables contains all join table updates made during the merge
         */
        joinTables: MergeUserJoinTableChanges

        /**
         * Tokens contains all access tokens that were updated during the merge
         */
        tokens: MergeUserTokenChange[]
    }

    /**
     * MergeUserTeamChange represents updates to team metadata during a merge
     */
    export interface MergeUserTeamChange {
        /**
         * ID is the unique identifier of the updated team
         */
        id: string

        /**
         * CreatedBy contains the new user ID that replaced the old user ID
         */
        "created_by": string

        /**
         * ModifiedBy contains the new user ID that replaced the old user ID
         */
        "modified_by": string
    }

    /**
     * MergeUserThreadChanges represents changes to threads during a user merge
     */
    export interface MergeUserThreadChanges {
        /**
         * Threads contains thread IDs from ss_threads where created_by was changed
         */
        threads: string[]

        /**
         * Recent contains thread IDs from dat_recent_threads where user_id
         * was updated
         */
        recent: string[]
    }

    /**
     * MergeUserThreadJoinChange represents updates to a thread-collection
     * join record
     */
    export interface MergeUserThreadJoinChange {
        /**
         * JoinID is the unique identifier for this thread-collection association
         */
        "join_id": string

        /**
         * CreatedBy contains the new user ID that replaced the old user ID in the
         * created_by field
         */
        "created_by": string

        /**
         * ModifiedBy contains the new user ID that replaced the old user ID in the
         * modified_by field
         */
        "modified_by": string

        /**
         * DeletedBy contains the new user ID if the old user ID was found in the
         * deleted_by field
         */
        "deleted_by": string
    }

    /**
     * MergeUserTokenChange represents updates to collection access tokens
     * during a merge
     */
    export interface MergeUserTokenChange {
        /**
         * Token is the token value that was updated
         */
        token: string
    }

    /**
     * MergeUserWorkingContextChange represents a working context that was deleted
     * during a merge
     */
    export interface MergeUserWorkingContextChange {
        /**
         * UserID is the user associated with the deleted working context
         */
        "user_id": string

        /**
         * OrganizationID is the organization associated with the deleted working context
         */
        "organization_id": string

        /**
         * TenantID is the tenant associated with the deleted working context
         */
        "tenant_id": string
    }

    /**
     * MergeUsersParams represents the parameters required to merge two user records
     * the routine will determine which user ID is the old user ID and which is the
     * new user ID based on an evaluation of system state.
     */
    export interface MergeUsersParams {
        oldUserId: stid.UserStringID
        newUserID: stid.UserStringID
        reason: string
    }

    export interface Metadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
    }

    export interface OrganizationMembership {
        metadata: Metadata
        /**
         * ID The globally unique identifier of the organization.
         */
        organizationId: stid.OrganizationStringID

        tenantId: stid.OrganizationStringID
        /**
         * Occupation The user's occupation. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        occupation: string

        /**
         * Department The user's department. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        department: string

        /**
         * Title The user's title. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        title: string

        /**
         * CostCenter The user's cost center. Useful to help breakdown billing for organizations
         * with complex billing needs such as interdepartmental billing.
         */
        costCenter: string
    }

    export type TokenRefreshReason = string

    /**
     * UserAuthSyncCmdParams represents a command to sync a user's auth data.
     */
    export interface UserAuthSyncCmdParams {
        /**
         * Email is the user's email address as provided by the authentication provider.
         */
        email: string

        /**
         * DisplayName is the user's display name as provided by the authentication provider.
         */
        displayName: string

        /**
         * IsEmailVerified is true if the user's email address has been verified as provided by the authentication provider.
         */
        isEmailVerified: boolean

        /**
         * PhotoURL is the URL to the user's profile picture as provided by the authentication provider.
         */
        photoURL: string

        /**
         * DeviceID is the unique identifier for the device that the user is using. The client is responsible for
         * managing this value. It is used to track the user's devices to aid with security and user experience.
         */
        deviceID: stid.DeviceStringID

        /**
         * UserAgent is the user agent string from the client.
         */
        userAgent: string

        /**
         * Locale is the user's locale as provided by the client.
         */
        locale: string

        /**
         * DefaultProjectName are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultProjectName: string

        /**
         * DefaultProjectDisplayName are used to create a new user's default organization and project. The client
         * should provide a localized string.  This value is always required but will have no effect on returning users.
         */
        defaultProjectDisplayName: string

        /**
         * DefaultProjectSlug are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultProjectSlug: slugs.Slug

        /**
         * DefaultOrganizationName are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationName: string

        /**
         * DefaultOrganizationDisplayName are used to create a new user's default organization and project. The client
         * should provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationDisplayName: string

        /**
         * DefaultOrganizationSlug are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationSlug: slugs.Slug

        /**
         * DefaultOrganizationCollectionLabel is the default label for the root organization collection. It will be applied
         * automatically to the root collection when the organization is created. The server will assign a default if omitted.
         */
        defaultOrganizationCollectionLabel: string

        /**
         * DefaultPersonalCollectionLabel is the default label for the root personal collection. It will be applied automatically
         * to the root collection when the user is created. The server will assign a default if omitted.
         */
        defaultPersonalCollectionLabel: string

        /**
         * DefaultFavoritesCollectionLabel is the default label for the root favorites collection. It will be applied automatically
         * to the root collection when the user is created. The server will assign a default if omitted.
         */
        defaultFavoritesCollectionLabel: string

        /**
         * WorkflowVariants allows for different AuthSync processing behaviors.
         * This value should be omitted or left as an empty string unless otherwise
         * instructed.
         */
        workflowVariants: UserAuthSyncVariant[]
    }

    export type UserAuthSyncVariant = string

    export interface UserMetadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
        /**
         * MarkedForAbuseAt The time the user was marked for abuse.
         */
        markedForAbuseAt: string

        /**
         * MarkedForAbuseBy The id of the user who marked the user for abuse.
         */
        markedForAbuseBy: stid.UserStringID
    }

    /**
     * UserState represents the state of a user.
     */
    export interface UserState {
        metadata: UserMetadata
        /**
         * ID The globally unique identifier of the user. It may be sourced from Firebase.
         */
        id: stid.UserStringID

        /**
         * Version The version of the user resource.
         */
        version: number

        /**
         * ETag The ETag of the user resource. Used for cache invalidation.
         */
        etag: string

        /**
         * AuthDisplayName The user's full name as sourced from an external auth system.
         */
        authDisplayName: string

        /**
         * PictureURL The URL of the user's profile picture sourced from an external auth system.
         */
        authPictureURL: string

        /**
         * Email The user's email address.
         */
        email: string

        /**
         * DisplayName The user's full name.
         */
        displayName: string

        /**
         * PhoneticName The user's phonetic name, if available, to help with pronunciation.
         */
        phoneticName: string

        /**
         * GivenName The user's first name.
         */
        givenName: string

        /**
         * FamilyName The user's last name.
         */
        familyName: string

        /**
         * Locale The user's preferred locale.
         */
        locale: string

        /**
         * PictureURL The URL of the user's profile picture.
         */
        pictureURL: string

        /**
         * Emails A list of all emails associated with the user.
         */
        emails: EmailAddress[]

        /**
         * Organizations A list of organizations the user is a member of.
         */
        organizations: OrganizationMembership[]

        /**
         * Addresses A list of addresses associated with the user.
         */
        address: Address[]

        markedForAbuseAt: string
        /**
         * MarkedForAbuseBy The id of the user who marked the user for abuse.
         */
        markedForAbuseBy: stid.UserStringID

        /**
         * Slug represents the vanity URL part for the user.
         */
        slug: slugs.Slug

        /**
         * LifecycleState The state of the user's lifecycle, assigned by the server.
         */
        lifecycleState: LifecycleState

        /**
         * PotentialScore is a score assigned to a user based on various signals such as their email address.
         * This score helps in determining the potential value of the user, influencing decisions on resource allocation
         * and the degree of free access granted to the user. For instance, a user from a large enterprise company
         * would typically have a higher score compared to a user signing up with a disposable email address.
         */
        potentialScore: number

        /**
         * ExternalIdentifiers A list of external identifiers associated with the user.
         */
        externalIdentifiers: ExternalIdentifier[]

        /**
         * MaintenanceActivities A list of maintenance activities that have been
         * applied to the user. This is useful for tracking changes to the user
         * or upgrade/migrations that have been applied to the user.
         */
        maintenanceActivities: MaintenanceActivities
    }

    /**
     * WorkingContext conveys the current working focus of the user.
     */
    export interface WorkingContext {
        organizationId: stid.OrganizationStringID
        tenantId: stid.OrganizationStringID
        CollectionTree: curator.CollectionTrees
    }
}

export namespace xid {
    /**
     * ID represents a unique request id
     */
    export type ID = number[]
}



function encodeQuery(parts: Record<string, string | string[]>): string {
    const pairs: string[] = []
    for (const key in parts) {
        const val = (Array.isArray(parts[key]) ?  parts[key] : [parts[key]]) as string[]
        for (const v of val) {
            pairs.push(`${key}=${encodeURIComponent(v)}`)
        }
    }
    return pairs.join("&")
}

// makeRecord takes a record and strips any undefined values from it,
// and returns the same record with a narrower type.
// @ts-ignore - TS ignore because makeRecord is not always used
function makeRecord<K extends string | number | symbol, V>(record: Record<K, V | undefined>): Record<K, V> {
    for (const key in record) {
        if (record[key] === undefined) {
            delete record[key]
        }
    }
    return record as Record<K, V>
}


// mustBeSet will throw an APIError with the Data Loss code if value is null or undefined
function mustBeSet<A>(field: string, value: A | null | undefined): A {
    if (value === null || value === undefined) {
        throw new APIError(
            500,
            {
                code: ErrCode.DataLoss,
                message: `${field} was unexpectedly ${value}`, // ${value} will create the string "null" or "undefined"
            },
        )
    }
    return value
}

function encodeWebSocketHeaders(headers: Record<string, string>) {
    // url safe, no pad
    const base64encoded = btoa(JSON.stringify(headers))
      .replaceAll("=", "")
      .replaceAll("+", "-")
      .replaceAll("/", "_");
    return "encore.dev.headers." + base64encoded;
}

class WebSocketConnection {
    public ws: WebSocket;

    private hasUpdateHandlers: (() => void)[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        let protocols = ["encore-ws"];
        if (headers) {
            protocols.push(encodeWebSocketHeaders(headers))
        }

        this.ws = new WebSocket(url, protocols)

        this.on("error", () => {
            this.resolveHasUpdateHandlers();
        });

        this.on("close", () => {
            this.resolveHasUpdateHandlers();
        });
    }

    resolveHasUpdateHandlers() {
        const handlers = this.hasUpdateHandlers;
        this.hasUpdateHandlers = [];

        for (const handler of handlers) {
            handler()
        }
    }

    async hasUpdate() {
        // await until a new message have been received, or the socket is closed
        await new Promise((resolve) => {
            this.hasUpdateHandlers.push(() => resolve(null))
        });
    }

    on(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.addEventListener(type, handler);
    }

    off(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.removeEventListener(type, handler);
    }

    close() {
        this.ws.close();
    }
}

export class StreamInOut<Request, Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamIn<Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamOut<Request, Response> {
    public socket: WebSocketConnection;
    private responseValue: Promise<Response>;

    constructor(url: string, headers?: Record<string, string>) {
        let responseResolver: (_: any) => void;
        this.responseValue = new Promise((resolve) => responseResolver = resolve);

        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            responseResolver(JSON.parse(event.data))
        });
    }

    async response(): Promise<Response> {
        return this.responseValue;
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }
}
// CallParameters is the type of the parameters to a method call, but require headers to be a Record type
type CallParameters = Omit<RequestInit, "method" | "body" | "headers"> & {
    /** Headers to be sent with the request */
    headers?: Record<string, string>

    /** Query parameters to be sent with the request */
    query?: Record<string, string | string[]>
}

// AuthDataGenerator is a function that returns a new instance of the authentication data required by this API
export type AuthDataGenerator = () =>
  | string
  | Promise<string | undefined>
  | undefined;

// A fetcher is the prototype for the inbuilt Fetch function
export type Fetcher = typeof fetch;

const boundFetch = fetch.bind(this);

class BaseClient {
    readonly baseURL: string
    readonly fetcher: Fetcher
    readonly headers: Record<string, string>
    readonly requestInit: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }
    readonly authGenerator?: AuthDataGenerator

    constructor(baseURL: string, options: ClientOptions) {
        this.baseURL = baseURL
        this.headers = {}

        // Add User-Agent header if the script is running in the server
        // because browsers do not allow setting User-Agent headers to requests
        if (typeof window === "undefined") {
            this.headers["User-Agent"] = "storytell-ai-platfor-cds2-Generated-TS-Client (Encore/v1.46.4)";
        }

        this.requestInit = options.requestInit ?? {};

        // Setup what fetch function we'll be using in the base client
        if (options.fetcher !== undefined) {
            this.fetcher = options.fetcher
        } else {
            this.fetcher = boundFetch
        }

        // Setup an authentication data generator using the auth data token option
        if (options.auth !== undefined) {
            const auth = options.auth
            if (typeof auth === "function") {
                this.authGenerator = auth
            } else {
                this.authGenerator = () => auth
            }
        }
    }

    async getAuthData(): Promise<CallParameters | undefined> {
        let authData: string | undefined;

        // If authorization data generator is present, call it and add the returned data to the request
        if (this.authGenerator) {
            const mayBePromise = this.authGenerator();
            if (mayBePromise instanceof Promise) {
                authData = await mayBePromise;
            } else {
                authData = mayBePromise;
            }
        }

        if (authData) {
            const data: CallParameters = {};

            data.headers = {};
            data.headers["Authorization"] = "Bearer " + authData;

            return data;
        }

        return undefined;
    }

    // createStreamInOut sets up a stream to a streaming API endpoint.
    async createStreamInOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamInOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamInOut(this.baseURL + path + queryString, headers);
    }

    // createStreamIn sets up a stream to a streaming API endpoint.
    async createStreamIn<Response>(path: string, params?: CallParameters): Promise<StreamIn<Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamIn(this.baseURL + path + queryString, headers);
    }

    // createStreamOut sets up a stream to a streaming API endpoint.
    async createStreamOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamOut(this.baseURL + path + queryString, headers);
    }

    // callTypedAPI makes an API call, defaulting content type to "application/json"
    public async callTypedAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
        return this.callAPI(method, path, body, {
            ...params,
            headers: { "Content-Type": "application/json", ...params?.headers }
        });
    }

    // callAPI is used by each generated API method to actually make the request
    public async callAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
        let { query, headers, ...rest } = params ?? {}
        const init = {
            ...this.requestInit,
            ...rest,
            method,
            body: body ?? null,
        }

        // Merge our headers with any predefined headers
        init.headers = {...this.headers, ...init.headers, ...headers}

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                init.headers = {...init.headers, ...authData.headers};
            }
        }

        // Make the actual request
        const queryString = query ? '?' + encodeQuery(query) : ''
        const response = await this.fetcher(this.baseURL+path+queryString, init)

        // handle any error responses
        if (!response.ok) {
            // try and get the error message from the response body
            let body: APIErrorResponse = { code: ErrCode.Unknown, message: `request failed: status ${response.status}` }

            // if we can get the structured error we should, otherwise give a best effort
            try {
                const text = await response.text()

                try {
                    const jsonBody = JSON.parse(text)
                    if (isAPIErrorResponse(jsonBody)) {
                        body = jsonBody
                    } else {
                        body.message += ": " + JSON.stringify(jsonBody)
                    }
                } catch {
                    body.message += ": " + text
                }
            } catch (e) {
                // otherwise we just append the text to the error message
                body.message += ": " + String(e)
            }

            throw new APIError(response.status, body)
        }

        return response
    }
}

/**
 * APIErrorDetails represents the response from an Encore API in the case of an error
 */
interface APIErrorResponse {
    code: ErrCode
    message: string
    details?: any
}

function isAPIErrorResponse(err: any): err is APIErrorResponse {
    return (
        err !== undefined && err !== null &&
        isErrCode(err.code) &&
        typeof(err.message) === "string" &&
        (err.details === undefined || err.details === null || typeof(err.details) === "object")
    )
}

function isErrCode(code: any): code is ErrCode {
    return code !== undefined && Object.values(ErrCode).includes(code)
}

/**
 * APIError represents a structured error as returned from an Encore application.
 */
export class APIError extends Error {
    /**
     * The HTTP status code associated with the error.
     */
    public readonly status: number

    /**
     * The Encore error code
     */
    public readonly code: ErrCode

    /**
     * The error details
     */
    public readonly details?: any

    constructor(status: number, response: APIErrorResponse) {
        // extending errors causes issues after you construct them, unless you apply the following fixes
        super(response.message);

        // set error name as constructor name, make it not enumerable to keep native Error behavior
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/new.target#new.target_in_constructors
        Object.defineProperty(this, 'name', {
            value:        'APIError',
            enumerable:   false,
            configurable: true,
        })

        // fix the prototype chain
        if ((Object as any).setPrototypeOf == undefined) {
            (this as any).__proto__ = APIError.prototype
        } else {
            Object.setPrototypeOf(this, APIError.prototype);
        }

        // capture a stack trace
        if ((Error as any).captureStackTrace !== undefined) {
            (Error as any).captureStackTrace(this, this.constructor);
        }

        this.status = status
        this.code = response.code
        this.details = response.details
    }
}

/**
 * Typeguard allowing use of an APIError's fields'
 */
export function isAPIError(err: any): err is APIError {
    return err instanceof APIError;
}

export enum ErrCode {
    /**
     * OK indicates the operation was successful.
     */
    OK = "ok",

    /**
     * Canceled indicates the operation was canceled (typically by the caller).
     *
     * Encore will generate this error code when cancellation is requested.
     */
    Canceled = "canceled",

    /**
     * Unknown error. An example of where this error may be returned is
     * if a Status value received from another address space belongs to
     * an error-space that is not known in this address space. Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * Encore will generate this error code in the above two mentioned cases.
     */
    Unknown = "unknown",

    /**
     * InvalidArgument indicates client specified an invalid argument.
     * Note that this differs from FailedPrecondition. It indicates arguments
     * that are problematic regardless of the state of the system
     * (e.g., a malformed file name).
     *
     * This error code will not be generated by the gRPC framework.
     */
    InvalidArgument = "invalid_argument",

    /**
     * DeadlineExceeded means operation expired before completion.
     * For operations that change the state of the system, this error may be
     * returned even if the operation has completed successfully. For
     * example, a successful response from a server could have been delayed
     * long enough for the deadline to expire.
     *
     * The gRPC framework will generate this error code when the deadline is
     * exceeded.
     */
    DeadlineExceeded = "deadline_exceeded",

    /**
     * NotFound means some requested entity (e.g., file or directory) was
     * not found.
     *
     * This error code will not be generated by the gRPC framework.
     */
    NotFound = "not_found",

    /**
     * AlreadyExists means an attempt to create an entity failed because one
     * already exists.
     *
     * This error code will not be generated by the gRPC framework.
     */
    AlreadyExists = "already_exists",

    /**
     * PermissionDenied indicates the caller does not have permission to
     * execute the specified operation. It must not be used for rejections
     * caused by exhausting some resource (use ResourceExhausted
     * instead for those errors). It must not be
     * used if the caller cannot be identified (use Unauthenticated
     * instead for those errors).
     *
     * This error code will not be generated by the gRPC core framework,
     * but expect authentication middleware to use it.
     */
    PermissionDenied = "permission_denied",

    /**
     * ResourceExhausted indicates some resource has been exhausted, perhaps
     * a per-user quota, or perhaps the entire file system is out of space.
     *
     * This error code will be generated by the gRPC framework in
     * out-of-memory and server overload situations, or when a message is
     * larger than the configured maximum size.
     */
    ResourceExhausted = "resource_exhausted",

    /**
     * FailedPrecondition indicates operation was rejected because the
     * system is not in a state required for the operation's execution.
     * For example, directory to be deleted may be non-empty, an rmdir
     * operation is applied to a non-directory, etc.
     *
     * A litmus test that may help a service implementor in deciding
     * between FailedPrecondition, Aborted, and Unavailable:
     *  (a) Use Unavailable if the client can retry just the failing call.
     *  (b) Use Aborted if the client should retry at a higher-level
     *      (e.g., restarting a read-modify-write sequence).
     *  (c) Use FailedPrecondition if the client should not retry until
     *      the system state has been explicitly fixed. E.g., if an "rmdir"
     *      fails because the directory is non-empty, FailedPrecondition
     *      should be returned since the client should not retry unless
     *      they have first fixed up the directory by deleting files from it.
     *  (d) Use FailedPrecondition if the client performs conditional
     *      REST Get/Update/Delete on a resource and the resource on the
     *      server does not match the condition. E.g., conflicting
     *      read-modify-write on the same resource.
     *
     * This error code will not be generated by the gRPC framework.
     */
    FailedPrecondition = "failed_precondition",

    /**
     * Aborted indicates the operation was aborted, typically due to a
     * concurrency issue like sequencer check failures, transaction aborts,
     * etc.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     */
    Aborted = "aborted",

    /**
     * OutOfRange means operation was attempted past the valid range.
     * E.g., seeking or reading past end of file.
     *
     * Unlike InvalidArgument, this error indicates a problem that may
     * be fixed if the system state changes. For example, a 32-bit file
     * system will generate InvalidArgument if asked to read at an
     * offset that is not in the range [0,2^32-1], but it will generate
     * OutOfRange if asked to read from an offset past the current
     * file size.
     *
     * There is a fair bit of overlap between FailedPrecondition and
     * OutOfRange. We recommend using OutOfRange (the more specific
     * error) when it applies so that callers who are iterating through
     * a space can easily look for an OutOfRange error to detect when
     * they are done.
     *
     * This error code will not be generated by the gRPC framework.
     */
    OutOfRange = "out_of_range",

    /**
     * Unimplemented indicates operation is not implemented or not
     * supported/enabled in this service.
     *
     * This error code will be generated by the gRPC framework. Most
     * commonly, you will see this error code when a method implementation
     * is missing on the server. It can also be generated for unknown
     * compression algorithms or a disagreement as to whether an RPC should
     * be streaming.
     */
    Unimplemented = "unimplemented",

    /**
     * Internal errors. Means some invariants expected by underlying
     * system has been broken. If you see one of these errors,
     * something is very broken.
     *
     * This error code will be generated by the gRPC framework in several
     * internal error conditions.
     */
    Internal = "internal",

    /**
     * Unavailable indicates the service is currently unavailable.
     * This is a most likely a transient condition and may be corrected
     * by retrying with a backoff. Note that it is not always safe to retry
     * non-idempotent operations.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     *
     * This error code will be generated by the gRPC framework during
     * abrupt shutdown of a server process or network connection.
     */
    Unavailable = "unavailable",

    /**
     * DataLoss indicates unrecoverable data loss or corruption.
     *
     * This error code will not be generated by the gRPC framework.
     */
    DataLoss = "data_loss",

    /**
     * Unauthenticated indicates the request does not have valid
     * authentication credentials for the operation.
     *
     * The gRPC framework will generate this error code when the
     * authentication metadata is invalid or a Credentials callback fails,
     * but also expect authentication middleware to generate it.
     */
    Unauthenticated = "unauthenticated",
}
