import type { stid } from "@repo/client";
import type { CollectionTree } from "~/domains/collections/collections.types";

// TODO: Guest needs to be dynamic based on deviceID.
export const GUEST_IDENTITY: UnauthenticatedIdentity = {
  isAuthenticated: false,
  workingContext: {
    organizationId: "" as stid.OrganizationStringID,
    tenantId: "" as stid.OrganizationStringID,
    collectionTree: [],
    operationId: "" as stid.OperationStringID,
  },
  isGuest: true,
} as const;

/**
 * WorkingContext is a type that represents the current working context of the user.
 */
export type WorkingContext = {
  // organizationId represents the current organization the user is working within and
  // acts as a container for all knowledge, threads, and other resources.
  organizationId: stid.OrganizationStringID;
  // tenantId represents the top-level organization which owns the organization. It may be
  // the same as the organizationId.
  tenantId: stid.OrganizationStringID;
  collectionTree: CollectionTree[];
  operationId: stid.OperationStringID;
};

export type AuthenticatedIdentity = {
  userId: stid.UserStringID;
  displayName: string;
  email: string;
  emailVerified: boolean;
  isAuthenticated: boolean;
  didSignUp: boolean;
  didClaimInvites: boolean;
  pictureURL: string;
  workingContext: WorkingContext;
  isGuest: boolean;
  potentialScore: number;
};

export type UnauthenticatedIdentity = {
  isAuthenticated: boolean;
  workingContext: WorkingContext;
  isGuest: boolean;
};
export type Identity = AuthenticatedIdentity | UnauthenticatedIdentity;

/**
 * isAuthenticatedIdentity is a type guard for AuthenticatedIdentity.
 * @param arg
 */
export function isAuthenticatedIdentity(arg: unknown): arg is AuthenticatedIdentity {
  // biome-ignore lint/suspicious/noExplicitAny: type guard
  const o = arg as any;
  return o && typeof o.displayName === "string" && typeof o.isAuthenticated === "boolean" && o.isAuthenticated;
}

export type IdentityStore = {
  identity: Identity;
  error?: string;
};
